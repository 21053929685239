import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  files: {},
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setFileData: (state, action) => {
      const {
        fieldName,
        fileName,
        fileBase64,
        fileError,
        pathname,
        accessurl,
      } = action.payload;
      state.files[fieldName] = {
        fileName: fileName || state.files[fieldName]?.fileName || "",
        fileBase64: fileBase64 || state.files[fieldName]?.fileBase64 || "",
        pathname: pathname || state.files[fieldName]?.pathname || "",
        accessurl: accessurl || state.files[fieldName]?.accessurl || "",
        fileError: fileError || null,
      };
    },

    clearFileData: (state, action) => {
      const fieldName = action.payload;
      if (fieldName) {
        delete state.files[fieldName];
      } else {
        state.files = {};
      }
    },

    setFileError: (state, action) => {
      const { fieldName, error } = action.payload;
      if (state.files[fieldName]) {
        state.files[fieldName].fileError = error;
      } else {
        state.files[fieldName] = { fileError: error };
      }
    },

    clearFileErrors: (state) => {
      Object.keys(state.files).forEach((key) => {
        if (state.files[key]) {
          state.files[key].fileError = null;
        }
      });
    },
  },
});

export const { setFileData, clearFileData, setFileError, clearFileErrors } =
  fileSlice.actions;

export default fileSlice.reducer;
