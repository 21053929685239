import React, { useEffect, useState } from 'react';
import PlatformList from '../features/platform/PlatformList';
import { Button, Space } from 'antd';
import PlatformForm from '../features/platform/PlatformForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlatforms, setFilters } from '../features/platform/platformSlice';
import fields from '../features/platform/fields';
import AddFilterButton from '../components/shared/filters/addFilterButton';
import FilterTags from '../components/shared/filters/filterTags';
import CustomBreadcrumb from '../components/shared/CustomBreadcrumb';

const PlatformPage = () => {
  const [showForm, setshowForm] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { label: 'Platform' },
  ]);
  const dispatch = useDispatch();
  const { limit, pageIndex, sorting, filters } = useSelector(
    (state) => state.platforms
  );

  useEffect(() => {
    dispatch(fetchPlatforms({ pageIndex, limit, sorting, filters }));
  }, [pageIndex, limit, sorting, filters]);

  const handleCloseForm = () => {
    setshowForm(false);
    setInitialData(null);
    setBreadcrumbItems([{ label: 'Platform' }]);
  };

  const handleAdd = () => {
    setInitialData(null);
    setshowForm(true);
    setBreadcrumbItems([
      { label: 'Platform', onClick: handleCloseForm },
      { label: 'Add Platform' },
    ]);
  };

  const handleEdit = (data) => {
    setInitialData(data);
    setshowForm(true);
    setBreadcrumbItems([
      { label: 'Platform', onClick: handleCloseForm },
      { label: 'Edit Platform' },
    ]);
  };

  const handleFilters = (type, data, index) => {
    let allFilters = [...filters];
    if (type === 'add') {
      allFilters.push(data);
    } else if (type === 'update') {
      allFilters[index] = data;
    } else if (type === 'remove') {
      allFilters.splice(index, 1);
    }
    dispatch(setFilters(allFilters));
  };

  return (
    <div>
      <CustomBreadcrumb items={breadcrumbItems} />
      {!showForm ? (
        <div>
          <div className="flex justify-end mt-2 mb-2">
            <Space>
              <AddFilterButton fields={fields} handleFilters={handleFilters} />
              <Button type="default" onClick={handleAdd}>
                + Add Platform
              </Button>
            </Space>
          </div>
          <FilterTags filterList={filters} handleFilters={handleFilters} />
          <PlatformList handleEdit={handleEdit} />
        </div>
      ) : (
        <PlatformForm
          handleCloseForm={handleCloseForm}
          initialData={initialData}
        />
      )}
    </div>
  );
};

export default PlatformPage;
