import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import API_ENDPOINTS from '../../api/apiConfig';
import apiClient from '../../api/apiClient';

export const fetchPlatforms = createAsyncThunk(
  'platforms/fetchPlatforms',
  async ({ pageIndex, limit, sorting, filters }) => {
    let sort = [];
    if (sorting?.length) {
      sort = sorting?.map(
        (eachValue) => `${eachValue?.id},${eachValue?.desc ? 'desc' : 'asc'}`
      );
    }
    let filter = [];
    if (filters?.length) {
      filter = filters?.map((eachValue) => {
        return {
          [eachValue?.field?.value + (eachValue?.field?.with || '')]: {
            [eachValue?.condition]: eachValue?.value
              ? eachValue?.value?.toString()
              : '',
          },
        };
      });
    }
    const queryParams = new URLSearchParams({
      page: pageIndex,
      size: limit,
      sort: sort,
    }).toString();

    const response = await axios.post(
      `${API_ENDPOINTS.platform.list}?${queryParams}`,
      { filter: filter }
    );

    if (response?.status !== 200) {
      throw new Error(`Error: ${response?.status} - ${response?.message}`);
    }
    return response.data;
  }
);

export const createOrUpdatePlatform = createAsyncThunk(
  'platforms/createOrUpdatePlatform',
  async (payload, { dispatch, getState }) => {
    const response = await apiClient.post(
      `${API_ENDPOINTS.platform.createOrUpdate}`,
      payload
    );
    if (response?.status !== 201) {
      throw new Error(`Error: ${response?.status} - ${response?.message}`);
    }
    const state = getState().platforms;
    dispatch(
      fetchPlatforms({
        pageIndex: state.pageIndex,
        limit: state.limit,
        sort: state.sorting,
        filters: state.filters,
      })
    );
    return response.data;
  }
);

const platformSlice = createSlice({
  name: 'platforms',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
    pageIndex: 0,
    limit: 5,
    totalItems: 0,
    totalPages: 1,
    sorting: [],
    filters: [],
  },
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    setPageSize: (state, action) => {
      state.limit = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setFilters: (state, action) => {
      state.pageIndex = 0;
      state.filters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatforms.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlatforms.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload?.data?.content;
        state.totalItems = action.payload?.data?.totalElements;
        state.totalPages = action.payload?.data?.totalPages;
      })
      .addCase(fetchPlatforms.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createOrUpdatePlatform.fulfilled, (state, action) => {
        console.log(action.payload.message);
      });
  },
});
export const { setPageIndex, setPageSize, setSorting, setFilters } =
  platformSlice.actions;

export default platformSlice.reducer;
