import React from 'react';

const TagComponent = ({ label, onTagClick, onCloseClick }) => {
  return (
    <div className="inline-flex items-center px-3 py-1.5 mr-2 mb-2 text-sm font-medium text-blue-800 bg-blue-100 rounded-full">
      <span onClick={onTagClick} className="cursor-pointer">
        {label}
      </span>
      <button
        onClick={onCloseClick}
        className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
        aria-label="Remove filter"
      >
        &times;
      </button>
    </div>
  );
};

export default TagComponent;
