import React from "react";
import {
  AdjustmentsHorizontalIcon,
  DocumentTextIcon,
  MapPinIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { UsersIcon } from "@heroicons/react/20/solid";
import { DashboardTwoTone } from "@ant-design/icons";

const MenuItem = [
  {
    title: "Dashboard",
    icon: <DashboardTwoTone className="h-6 w-6" />,
    key: "1",
    path: "/dashboard",
  },

  {
    title: "Publisher",
    icon: <UsersIcon className="h-6 w-6" />,
    path: "/publishers",
    key: "2",
    submenu: [
      {
        title: "Publisher",
        path: "/publisher",
        icon: <UsersIcon className="h-6 w-6" />,
        key: "18",
      },
      {
        title: "Publisher Location",
        path: "/location",
        icon: <MapPinIcon className="h-6 w-6" />,
        key: "19",
      },
      {
        title: "Society",
        path: "/society",
        icon: <UserGroupIcon className="h-6 w-6" />,
        key: "20",
      },
      {
        title: "Partner",
        icon: <UserCircleIcon className="h-6 w-6" />,
        path: "/partner",
        key: "21",
      },
    ],
  },
  {
    title: "Configuration",
    icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
    path: "/configuration",
    key: "3",
    submenu: [
      {
        title: "Editor Role",
        icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
        path: "/editor-role",
        key: "22",
      },
      {
        title: "Editor",
        icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
        path: "/editor",
        key: "23",
      },
      {
        title: "Author",
        icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
        path: "/author",
        key: "24",
      },
      {
        title: "Access Type",
        icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
        path: "/access-type",
        key: "25",
      },
      {
        title: "License Type",
        icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
        path: "/license-type",
        key: "26",
      },
      {
        title: "Subject",
        icon: <AdjustmentsHorizontalIcon className="h-6 w-6" />,
        path: "/subjects",
        key: "27",
      },
    ],
  },

  {
    title: "Journal",
    icon: <DocumentTextIcon className="h-6 w-6" />,
    path: "/journal",
    key: "5",
    submenu: [
      {
        title: "Article Type",
        icon: <DocumentTextIcon className="h-6 w-6" />,
        path: "/articleType",
        key: "36",
      },
      {
        title: "Journal",
        icon: <DocumentTextIcon className="h-6 w-6" />,
        path: "/journals",
        key: "37",
      },
      {
        title: "Volume",
        icon: <DocumentTextIcon className="h-6 w-6" />,
        path: "/volume",
        key: "38",
      },
      {
        title: "Issue",
        icon: <DocumentTextIcon className="h-6 w-6" />,
        path: "/issue",
        key: "39",
      },
      {
        title: "Article",
        icon: <DocumentTextIcon className="h-6 w-6" />,
        path: "/articles",
        key: "40",
      },
    ],
  },
];

const getVisibleMenuItems = () => {
  return MenuItem;
};

export default getVisibleMenuItems;
