import React from "react";
import { message } from "antd";

const messageApi = message;

const showSuccessMessage = (content, duration = 3) => {
  messageApi.open({
    type: "success",
    content,
    duration,
  });
};

const showErrorMessage = (content, duration = 3) => {
  messageApi.open({
    type: "error",
    content,
    duration,
  });
};

export { showSuccessMessage, showErrorMessage };
