import React, { useEffect, useState } from 'react';
import { Button, Form, Space } from 'antd';
import CustomInput from '../../components/shared/CustomInput';
import CustomTextArea from '../../components/shared/CustomTextArea';
import customizeRequiredMark from '../../components/shared/customizeRequiredMark';
import { useDispatch } from 'react-redux';
import { createOrUpdatePlatform } from './platformSlice';
import { toast } from 'react-toastify';

const PlatformForm = ({ handleCloseForm, initialData }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    platformName: '',
    platformDescription: '',
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        platformName: initialData?.platform_name,
        platformDescription: initialData?.platform_description,
      });
    }
  }, [initialData]);

  const formItems = {
    platformName: {
      required: true,
      label: 'Platform Name',
    },
    platformDescription: {
      required: false,
      label: 'Platform Description',
    },
  };

  const validateField = (name, value) => {
    const rule = formItems[name];

    if (!rule) {
      return '';
    }

    if (rule.required && !value) {
      return rule.label + ' is required';
    }

    return '';
  };

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    if (isFieldRequired(name)) {
      const error = validateField(name, value);
      setFormErrors((prevErrors) => {
        if (error) {
          return {
            ...prevErrors,
            [name]: error,
          };
        } else {
          const { [name]: removedError, ...rest } = prevErrors;
          return rest;
        }
      });
    }
  };

  const isFieldRequired = (name) => {
    const rule = formItems[name];
    return rule ? rule.required : false;
  };

  const handleSubmit = async () => {
    const errorObj = Object.keys(formData).reduce((acc, key) => {
      const error = validateField(key, formData[key]);
      if (error) acc[key] = error;
      return acc;
    }, {});

    setFormErrors(errorObj);
    if (Object.keys(errorObj).length === 0) {
      let payload = {
        id: initialData?.id || 0,
        platform_name: formData.platformName,
        platform_description: formData.platformDescription,
      };

      try {
        await dispatch(createOrUpdatePlatform(payload)).unwrap();
        toast.success(
          `Platform ${initialData ? 'Updated' : 'Saved'} Successfully`
        );
        handleClose();
      } catch (error) {
        toast.error(error?.message || error);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    handleCloseForm(false);
  };

  return (
    <div className="max-w-lg mx-auto mt-4 bg-white p-4 rounded shadow">
      <h2 className="text-2xl font-bold mb-4">Platform Form</h2>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={customizeRequiredMark}
      >
        {Object.keys(formItems).map((field) => {
          const { required, label } = formItems[field];

          return (
            <div className="custom-form" key={field}>
              <Form.Item
                label={label}
                required={required}
                validateStatus={formErrors[field] ? 'error' : ''}
                help={formErrors[field] || ''}
              >
                {field === 'platformDescription' ? (
                  <CustomTextArea
                    placeholder={label}
                    value={formData[field]}
                    onChange={(e) => handleChange(field, e.target.value)}
                  />
                ) : (
                  <CustomInput
                    placeholder={label}
                    value={formData[field]}
                    onChange={(e) => handleChange(field, e.target.value)}
                  />
                )}
              </Form.Item>
            </div>
          );
        })}

        <Form.Item>
          <Space>
            <Button onClick={() => handleSubmit()} type="primary">
              Submit
            </Button>
            <Button onClick={() => handleClose()}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PlatformForm;
