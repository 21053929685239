import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { usePostData } from "../../hooks/useData";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import PageTitle from "../../components/shared/PageTitle";
import { Alert, Button, Card, Form } from "antd";
import CustomInput from "../../components/shared/CustomInput";
import CustomDropdown from "../../components/shared/CustomDropdown";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntityData } from "../../store/slices/getListSlice";
import Loader from "../../components/shared/Loader";

const AddPublisherLocation = () => {
  const breadcrumbItems = [
    { label: "Publisher Location", onClick: () => navigate("/location") },
    { label: "Add Publisher Location" },
  ];

  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const [formValues, setFormValues] = useState({
    locationName: "",
    officeType: "",
    description: "",
    email: "",
    city: "",
    address: "",
    pincode: "",
    contactNo: "",
    website: "",
    fax: "",
    skype: "",
    state: "",
    twitterProfile: "",
    facebookProfile: "",
    instagramProfile: "",
    linkedInProfile: "",
    country: "",
  });
  const [isValidForm, setIsValidForm] = useState(true);
  const { mutate } = usePostData();
  const navigate = useNavigate();
  const { loading, data } = useSelector((state) => state.entityData);
  //   const [tableRows, setTableRows] = useState([{ id: 0, email: "" }]);
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleCancel = () => {
    setFormValues({
      locationName: "",
      officeType: "",
      description: "",
      email: "",
      city: "",
      address: "",
      pincode: "",
      contactNo: "",
      website: "",
      fax: "",
      skype: "",
      state: "",
      twitterProfile: "",
      facebookProfile: "",
      instagramProfile: "",
      linkedInProfile: "",
      country: "",
    });
    navigate("/location");
  };

  const saveLocation = () => {
    if (
      formValues.locationName === "" ||
      formValues.officeType === "" ||
      formValues.pincode === "" ||
      formValues.contactNo === "" ||
      formValues.country === ""
    ) {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
    const body = {
      id: 0,
      publisher_id: {
        id: 1,
      },
      location_name: formValues.locationName,
      office_type: formValues.officeType,
      description: formValues.description,
      email: formValues.email,
      city: formValues.city,
      address: formValues.address,
      pin_code: formValues.pincode,
      contact_number: formValues.contactNo,
      website: formValues.website,
      fax_number: formValues.fax,
      skype: formValues.skype,
      state: formValues.state,
      twitter_profile: formValues.twitterProfile,
      facebook_profile: formValues.facebookProfile,
      instagram_profile: formValues.instagramProfile,
      linkedin_profile: formValues.linkedInProfile,
      country_id: {
        id: 0,
      },
    };

    mutate(
      {
        url: ENDPOINTS.createOrUpdatePublisherLocations,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage("Publisher Location saved successfully!");
          setTimeout(() => {
            navigate("/location");
          }, 1000);
        },
        onError: (err) => {
          console.log("Error!", err);
        },
      }
    );
  };
  //   const emailColumns = [
  //     {
  //       title: "Email",
  //       dataIndex: "email",
  //       width: "30%",
  //       key: "email",
  //       render: (_, record, rowIndex) => {
  //         return (
  //           <div className="table-row-custom">
  //             <CustomInput
  //               type="text"
  //               name="email"
  //               value={formValues.email}
  //               onChange={handleChange}
  //               theme={theme}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       key: "action",
  //       width: "20%",
  //       render: (_, __, rowIndex) => (
  //         <div className="table-row-custom">
  //           <Button
  //             type="link"
  //             className="mt-3"
  //             icon={<DeleteOutline />}
  //             onClick={() => deleteRow(rowIndex)}
  //           />
  //         </div>
  //       ),
  //     },
  //   ];

  //   const addRow = () => {
  //     setTableRows([...tableRows, { id: 0, email: "" }]);
  //   };

  //   const deleteRow = (index) => {
  //     const updatedRows = tableRows.filter((_, rowIndex) => rowIndex !== index);
  //     setTableRows(updatedRows);
  //   };

  const handleValueChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(
      fetchEntityData({
        entity: "CountryDetails",
        page: 0,
        size: 20,
      })
    );
  }, [dispatch]);

  const countryOpts = data?.map((item) => ({
    label: item?.country_name,
    value: item?.id,
  }));

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Add Publisher Location" color={cardStyle.color} />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Location Name"
                type="text"
                required
                name="locationName"
                value={formValues.locationName}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Office Type"
                type="text"
                required
                name="officeType"
                value={formValues.officeType}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Description"
                type="text"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="City"
                type="text"
                name="city"
                value={formValues.city}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Address"
                type="text"
                name="address"
                value={formValues.address}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Pincode"
                required
                type="text"
                name="pincode"
                value={formValues.pincode}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Contact No."
                required
                type="text"
                name="contactNo"
                value={formValues.contactNo}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Email"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                theme={theme}
              />
            </div>

            <div className="col-md-4">
              <CustomInput
                label="Fax"
                type="text"
                name="fax"
                value={formValues.fax}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Skype"
                type="text"
                name="skype"
                value={formValues.skype}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="State"
                type="text"
                name="state"
                value={formValues.state}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomDropdown
                label="Country"
                name="country"
                value={formValues.country}
                onChange={handleValueChange}
                options={countryOpts}
                theme={theme}
                required
              />
            </div>
          </div>
          {/* <div className="row mt-2">
            <div className="col-md-12">
              <Form.Item
                label={
                  <span style={{ color: theme === "dark" ? "white" : "black" }}>
                    Email
                  </span>
                }
                labelCol={{ span: 24 }}
                style={{ marginBottom: "8px" }}
              >
                <Table
                  columns={emailColumns}
                  dataSource={tableRows}
                  pagination={false}
                  rowKey={(record, index) => record.id || index}
                  style={{
                    ...tableStyle,
                    marginTop: "0px",
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }}
                />
                <Button
                  onClick={addRow}
                  type="dashed"
                  style={{
                    marginTop: "10px",
                    float: "right",
                    height: "30px",
                    width: "100px",
                    ...buttonStyle,
                  }}
                >
                  + Add More
                </Button>
              </Form.Item>
            </div>
          </div> */}
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Twitter Profile"
                type="text"
                name="twitterProfile"
                value={formValues.twitterProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Facebook Profile"
                type="text"
                name="facebookProfile"
                value={formValues.facebookProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Instagram Profile"
                type="text"
                name="instagramProfile"
                value={formValues.instagramProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Linkedin Profile"
                type="text"
                name="linkedInProfile"
                value={formValues.linkedInProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Website"
                type="text"
                name="website"
                value={formValues.website}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveLocation}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddPublisherLocation;
