const BASE_URL = "http://3.6.90.36:8080/";

export const ENDPOINTS = {
  saveOrUpdateAccessTypes: `${BASE_URL}v1/domain/saveOrUpdateAccessTypes`,
  saveOrUpdateSubjects: `${BASE_URL}v1/domain/saveOrUpdateSubjects`,
  saveOrUpdateLicenseTypes: `${BASE_URL}v1/domain/saveOrUpdateLicenseTypes`,
  saveOrUpdateEditorRoles: `${BASE_URL}v1/domain/saveOrUpdateEditorRoles`,

  createOrUpdateSociety: `${BASE_URL}v1/publisher/createOrUpdateSociety`,
  createOrUpdatePublisher: `${BASE_URL}v1/publisher/createOrUpdatePublisher`,
  createOrUpdatePublisherLocations: `${BASE_URL}v1/publisher/createOrUpdatePublisherLocations`,

  saveOrUpdateJournal: `${BASE_URL}v1/journal/saveOrUpdateJournal`,
  saveOrUpdateSupplementaryFiles: `${BASE_URL}v1/journal/saveOrUpdateSupplementaryFiles`,
  saveOrUpdateJournalEditorRoles: `${BASE_URL}v1/journal/saveOrUpdateEditorRoles`,

  saveOrUpdateEditor: `${BASE_URL}v1/user/saveOrUpdateEditor`,

  getEntityData: `${BASE_URL}v1/generic/getEntityData`,
};
