import React, { useContext } from "react";
import { Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "tailwindcss/tailwind.css";
import ThemeContext from "./ThemeContext";
import CustomBreadcrumb from "./shared/CustomBreadcrumb";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const { theme } = useContext(ThemeContext);
  const lineChartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Articles",
        data: [10, 35, 5, 7, 3, 2, 0, 3, 6, 2, 10, 1],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Journals",
        data: [5, 7, 5, 10, 8, 5, 3, 5, 7, 3, 2, 0],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Issues",
        data: [1, 2, 2, 1, 0, 2, 1, 7, 5, 10, 8, 5],
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const pieChartData = {
    labels: ["Articles", "Journals", "Issues"],
    datasets: [
      {
        data: [62, 45, 10],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Average Publication",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Overall Publication Distribution",
      },
    },
  };
  return (
    <div className="container">
      {/* <CustomBreadcrumb items={[]} /> */}
      <div className="flex justify-between items-center mb-6">
        <h2
          className={`text-3xl text-${theme === "dark" ? "white" : "dark"} font-bold`}
        >
          Dashboard
        </h2>
        <p className={`text-${theme === "dark" ? "white" : "dark"} font-bold`}>
          Hi, Welcome to Dashboard!
        </p>
      </div>

      <div className="row mt-4">
        <div className="col-md-8">
          <div className={`data-theme ${theme} p-4 mb-8 rounded-lg shadow-md`}>
            <h3
              className={`text-${theme === "dark" ? "white" : "dark"} text-xl font-semibold mb-4`}
            >
              Monthly Average Publication
            </h3>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        </div>
        <div className="col-md-4">
          <div
            className={` data-theme ${theme} p-4 mb-8 rounded-lg shadow-md`}
            style={{ height: "464px" }}
          >
            <h3 className="text-xl font-semibold mb-4"></h3>
            <Pie data={pieChartData} options={pieChartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
