import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageIndex, setPageSize, setSorting } from './platformSlice';
import Table from '../../components/Table';

const PlatformList = ({ handleEdit }) => {
  const dispatch = useDispatch();
  const { limit, pageIndex, data, sorting, totalPages, totalItems } =
    useSelector((state) => state.platforms);

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      sort: true,
    },
    {
      Header: 'Platform Name',
      accessor: 'platform_name',
      sort: true,
    },
    {
      Header: 'Platform Description',
      accessor: 'platform_description',
      sort: true,
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: ({ row }) => (
        <button
          onClick={() => handleEdit(row.original)}
          className="text-blue-500 hover:text-blue-700 font-semibold"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleTableChange = ({ pageIndex, pageSize, sortBy }) => {
    dispatch(setPageIndex(pageIndex));
    dispatch(setPageSize(pageSize));
    dispatch(setSorting(sortBy));
  };

  return (
    <div>
      <div className="mt-4 bg-white p-4 rounded shadow">
        <Table
          columns={COLUMNS}
          data={data}
          manualSearch={true}
          manualPagination={true}
          totalPages={totalPages}
          initialPageIndex={pageIndex}
          initialPageSize={limit}
          initialSortBy={sorting}
          totalItems={totalItems}
          onTableChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default PlatformList;
