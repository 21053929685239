import { useMutation, useQuery } from "react-query";
import { getData, postData } from "../services/api";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setFileData, setFileError } from "../store/slices/fileSlice";
import Loader from "../components/shared/Loader";

const ErrorMessage = ({ error }) => <div>Error: {error}</div>;

export const usePostData = () => {
  const mutation = useMutation(
    async ({ url, data }) => {
      return await postData(url, data);
    },
    {
      onError: (error) => {
        console.error("Error posting data:", error);
      },
      onSuccess: (data) => {
        console.log("Data posted successfully:", data);
      },
    }
  );

  return {
    ...mutation,
    loadingComponent: mutation.isLoading ? <Loader /> : null,
    errorComponent: mutation.isError ? (
      <ErrorMessage error={mutation.error.message} />
    ) : null,
  };
};

export const useGetData = (url, queryKey) => {
  const query = useQuery(
    queryKey,
    async () => {
      const response = await getData(url);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      cacheTime: 100000,
      onError: (error) => {
        console.error("Error fetching data:", error);
      },
      onSuccess: (data) => {
        console.log("Data fetched successfully:", data);
      },
    }
  );

  return {
    ...query,
    loadingComponent: query.isLoading ? <Loader /> : null,
    errorComponent: query.isError ? (
      <ErrorMessage error={query.error.message} />
    ) : null,
  };
};

export const useFileHandlers = () => {
  const dispatch = useDispatch();
  const [fileInputKey, setFileInputKey] = useState({});
  const handleFileChange = (
    e,
    allowedFormats,
    maxSizeInMB = 60,
    dimensions,
    fieldName
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!allowedFormats.includes(file.type)) {
      dispatch(
        setFileError({
          fieldName,
          error: `Invalid file type. Only ${allowedFormats.join(", ")} are allowed.`,
        })
      );
      resetFileInput(fieldName);
      return;
    }

    const maxSize = maxSizeInMB * 1024 * 1024;
    if (file.size > maxSize) {
      dispatch(
        setFileError({
          fieldName,
          error: `File size should not exceed ${maxSizeInMB}MB.`,
        })
      );
      resetFileInput(fieldName);
      return;
    }

    if (file.type.startsWith("image/")) {
      const img = new Image();
      img.onload = () => {
        const { width, height } = img;
        if (dimensions) {
          if (width !== dimensions.width || height !== dimensions.height) {
            dispatch(
              setFileError({
                fieldName,
                error: `Image dimensions must be ${dimensions.width}px by ${dimensions.height}px.`,
              })
            );
            resetFileInput(fieldName);
            return;
          }
        }

        convertToBase64(file, fieldName);
      };
      img.src = URL.createObjectURL(file);
    } else {
      convertToBase64(file, fieldName);
    }
  };

  const resetFileInput = (fieldName) => {
    setFileInputKey((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName] + 1,
    }));
  };

  const convertToBase64 = (file, fieldName) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      let base64Data = reader.result;
      if (file.type.startsWith("image/")) {
        base64Data = base64Data.replace(/^data:image\/[a-zA-Z]*;base64,/, "");
      } else {
        base64Data = base64Data.replace(
          /^data:[a-zA-Z0-9]+\/[a-zA-Z0-9.-]+;base64,/,
          ""
        );
      }

      dispatch(
        setFileData({
          fieldName,
          fileName: file.name,
          fileBase64: base64Data,
        })
      );
    };
    reader.readAsDataURL(file);
  };

  return { handleFileChange, resetFileInput, convertToBase64, fileInputKey };
};
