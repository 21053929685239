const themeStyles = (theme) => ({
  cardStyle: {
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#fff",
    color: theme === "dark" ? "white" : "#001529",
    borderRadius: "10px",
    boxShadow:
      theme === "dark"
        ? "0px 0px 10px rgba(255, 255, 255, 0.2)"
        : "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  tableStyle: {
    backgroundColor: theme === "dark" ? "#2f2f2f" : "#f5f5f5",
    color: theme === "dark" ? "white" : "black",
  },
  components: {
    header: {
      cell: (props) => {
        const className = theme === "dark" ? "dark-header" : "light-header";
        return <th {...props} className={className} />;
      },
    },
  },
  buttonStyle: {
    backgroundColor: theme === "dark" ? "#001529" : "#1890ff",
    color: "white",
    borderRadius: "5px",
    padding: "10px 20px",
    marginRight: "10px",
  },
  rowClassName: (record, index) => {
    return theme === "dark" ? "dark-row" : "light-row";
  },
});

export default themeStyles;
