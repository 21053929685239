import React, { useState, useContext } from "react";
import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import ThemeContext from "./ThemeContext";
import Sider from "antd/es/layout/Sider";
import getVisibleMenuItems from "./MenuItem";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

const Navbar = ({ sidebarOpen }) => {
  const { theme } = useContext(ThemeContext);
  const [collapsed, setCollapsed] = useState(!sidebarOpen);
  const navigate = useNavigate();
  const location = useLocation();

  const visibleMenuItems = getVisibleMenuItems();

  const handleMenuClick = (e) => {
    navigate(e.key);
  };

  const mapMenuItems = (items) => {
    return items.map((item) => {
      if (item.submenu) {
        return {
          key: item.path,
          icon: item.icon,
          label: item.title,
          children: mapMenuItems(item.submenu),
        };
      }
      return {
        key: item.path,
        icon: item.icon,
        label: item.title,
      };
    });
  };

  const antMenuItems = mapMenuItems(visibleMenuItems);

  const getSelectedKeys = () => {
    for (let item of visibleMenuItems) {
      if (location.pathname.startsWith(item.path)) {
        return [item.path];
      }
      if (item.submenu) {
        for (let subItem of item.submenu) {
          if (location.pathname.startsWith(subItem.path)) {
            return [item.path];
          }
        }
      }
      return [location.pathname];
    }
  };
  const customTrigger = (
    <div
      style={{
        padding: "8px",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "#c8c5f7",
      }}
    >
      <div
        style={{
          backgroundColor: theme === "dark" ? "rgb(15 42 62)" : "#d8e1e8",
          display: "block",
        }}
      >
        {collapsed ? <CaretRightOutlined /> : <CaretLeftOutlined />}
      </div>
    </div>
  );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      theme={theme}
      onCollapse={(value) => setCollapsed(value)}
      width={250}
      trigger={customTrigger}
      style={{
        height: "100vh",
        // zIndex: 500,
        position: "relative",
        left: 0,
        top: 0,
        backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "#c8c5f7",
      }}
    >
      <div className="demo-logo-vertical" />
      <div
        style={{
          height: "100%",
          overflowY: "auto",
          scrollbarWidth: "none",
          backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "#c8c5f7",
        }}
      >
        <Menu
          theme={theme}
          mode="inline"
          selectedKeys={getSelectedKeys()}
          onClick={handleMenuClick}
          items={antMenuItems}
          style={{
            backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "#c8c5f7",
          }}
        />
      </div>
    </Sider>
  );
};

export default Navbar;
