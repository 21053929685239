import React, { useContext } from "react";
import ThemeContext from "../../components/ThemeContext";

const ArticleContent = ({ article }) => {
    const { theme } = useContext(ThemeContext);
  return (
    <div>
      {/* <h3>{article.title}</h3>
      <img
        src={article.imgUrl}
        alt={article.title}
        style={{ width: "600px", height: "auto" }}
      />
      <p>{article.content}</p> */}
      <div
        className="align-center"
        style={{ display: "grid", placeItems: "center", width: "100%" }}
      >
        <div
          className="col-md-2 my-4 text-center"
          style={{
            color: theme === "dark" ? "white" : "white",
            backgroundColor: theme === "dark" ? "black" : "rgb(0 20 41)",
            padding: "5px",
          }}
        >
          NEWBORN Vol 3 Issue 3
        </div>
        <img
          src="https://static.wixstatic.com/media/b19da8_eca5aa9b8ae040caa39852e2da0bcbd6~mv2.jpg/v1/fill/w_1252,h_800,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/JNB%20Jul-Sept%202024%20cover%2009302024.jpg"
          alt="Vol3Iss3"
          style={{ width: "600px", height: "auto" }}
        />
        <p className={`text-${theme === "dark" ? "white" : "dark"} my-3`}>
          A composite PDF file containing all articles in volume 3, issue 3;
          July-September 2024 is provided here. The references (with links) to
          all the individual articles are listed below:
        </p>
        <div
          onClick={() =>
            window.open(
              "https://www.globalnewbornsociety.org/_files/ugd/b19da8_b17ce419c760498dac0afee974462c91.pdf",
              "_blank"
            )
          }
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <img
            src="https://static.wixstatic.com/media/40d80b8089c8418a961cb75d96d491f7.png/v1/fill/w_50,h_67,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/40d80b8089c8418a961cb75d96d491f7.png"
            alt="pdf"
          />
        </div>

        <div
          style={{
            // marginTop: "20px",
            width: "90%",
            padding: "60px",
            textAlign: "justify",
            fontSize: "16px",
            color: theme === "dark" ? "white" : "black",
          }}
        >
          <p>
            Maheshwari A, Lui K, Motta M.{" "}
            <a
              href="https://www.newbornjournal.org/doi/JNB/pdf/10.5005/newborn-3-3-iv"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#7676ff",
              }}
            >
              Healthcare Bundles are Potentially Important in Neonatal Care as
              Many Disorders are Temporally Clustered.
            </a>{" "}
            Newborn 2024; 3(2):iv-ix. DOI:{" "}
            <a
              href="https://www.newbornjournal.org/doi/JNB/pdf/10.5005/newborn-3-3-iv"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#7676ff",
              }}
            >
              10.5005/newborn-3-3-iv.
            </a>
          </p>
          <hr className="my-3" />
          <p className="mb-3">
            The LAYA* Group of the Global Newborn Society.{" "}
            <a
              href="https://www.newbornjournal.org/doi/JNB/pdf/10.5005/jp-journals-11002-0107"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#7676ff",
              }}
            >
              A Care-bundle to Prevent Germinal Matrix–Intraventricular
              Hemorrhage in Neonates.
            </a>{" "}
            Newborn 2024; 3(3):157-179. DOI:{" "}
            <a
              href="https://www.newbornjournal.org/doi/JNB/pdf/10.5005/jp-journals-11002-0107"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underline",
                color: "#7676ff",
              }}
            >
              10.5005/jp-journals-11002-0096.
            </a>
            <em>*Looking At Your </em> practices in Application.
          </p>
          <p className="my-2">
            <strong style={{ textDecoration: "underline" }}>Abstract:</strong>{" "}
            Germinal matrix-intraventricular hemorrhages (GM-IVHs) can be seen
            in up to 25–30% of premature infants. These are associated with a
            major psychological, social, and financial challenge for
            care-providers and families caring for premature infants all over
            the world. The severity is usually classified based on the location
            and volume vis-à-vis that of the cerebral ventricles, including (A)
            Grade I GM-IVHs localized in the germinal matrix; (B and C) Grade II
            and III hemorrhages occupying less than and more than 50% of the
            ventricular cavities, respectively; and (D) Grade IV IVHs that
            extend into the surrounding parenchyma with/without a
            periventricular hemorrhagic infarction (PVH). Germinal
            matrix-intraventricular hemorrhages have been associated with
            impaired neurodevelopment (17.5%), static physical disabilities in
            cerebral palsy (7–63%), deafness (8.6%), and blindness (2.2%).
            Considering the complex etiopathogenesis of GM-IVH and the fact that
            most of these events occur within a temporally-delimited period of
            the first 72 hours after birth, there is increasing interest in the
            structured application of 3–5 well-accepted preventive measures as a
            quality improvement (QI) “care bundle” during the high-risk period.
            In this article, we have described the evidence on which our GM-IVH
            bundle is based. We have carefully evaluated antenatal factors such
            as the history of having received steroids and magnesium sulfate,
            perinatal measures such as delayed cord clamping, management of
            thrombocytopenia and/or coagulopathy, and postnatal measures such as
            maintaining a midline head position, cautious endotracheal
            suctioning and blood withdrawals, and avoidance of routine flushing
            of intravenous and arterial lines. Based on the strongest evidence
            and practice consensus, we have adopted a 4-point bundle to prevent
            GM-IVH in premature infants: (A) Appropriate neonatal resuscitation
            with, if possible, delayed cord clamping; (B) Golden-hour care; (C)
            Gentle care of outborn infants including safe transport and avoiding
            hemodynamic instability; and (D) if needed, management of perinatal
            thrombocytopenia and coagulopathy. In the next 3–5 years, we will
            report compliance and changes in the incidence/severity of GM-IVH at
            our centers.
          </p>
          <p className="my-2">
            <strong style={{ textDecoration: "underline" }}>
              Key scientific associations:
            </strong>{" "}
            Newborn, neonate, preterm, quality improvement, germinal matrix,
            periventricular hemorrhage, Institute of Health Care Improvement,
            GM-IVH Care-bundle, cerebral palsy, blindness, golden hour,
            antenatal corticosteroids, delayed cord clamping, implementation
            science, tocolytics, preterm premature rupture of membranes (PPROM),
            periventricular leukomalacia, periventricular hemorrhagic
            infarction, neurodevelopment, thrombocytopenia, coagulopathy,
            midline head position, cautious endotracheal suctioning, outborn
            infants, safe transport, compliance, premature cerebral vasculature,
            cerebral blood flow, ventricular distention, periventricular
            echodense area, glial precursor cells, angiogenesis, pericytes,
            immature basal lamina, astrocyte end-feet coverage, glial fibrillary
            acidic protein, epileptiform seizures, cognitive delays, vibrations,
            protocolization, PPROM, sexually-transmitted diseases,
            chorioamnionitis, erythromycin, clarithromycin, ceftriaxone,
            metronidazole, matrix metalloproteinase-8, American College of
            Obstetricians and Gynecologists, indomethacin, EPIPAGE-2, atosiban,
            oxytocin receptor antagonist, calcium channel blocker, APOSTEL-III
            trial, Ritodrine, German Neonatal Network, Cochrane review analysis,
            foramen ovale, early cord clamping, monochorionic twins,
            intrauterine growth restriction, hydrops fetalis, hypothermia,
            hypoglycemia, polyethylene bags, snap-open access port covers,
            air-boost on transport incubators, gel-filled thermostable
            mattresses, infrared thermometers, infrared thermography,
            International Electrotechnical Commission, Ground Ambulance
            Transport, noise, newborn emergency transport service (NETS) teams,
            real-time NIRS monitoring, sleep-wake states, REM sleep,
            Intubation-Surfactant delivery-and Extubation (InSurE), LISA (Less
            Invasive Surfactant Administration), SALSA (Surfactant
            Administration through the Laryngeal or Supraglottic Airway),
            blenders, permissive hypercapnia, cerebrovascular autoregulation,
            pain, accentuated neuronal excitation, apoptosis, subcortical grey
            matter maturation, pain-induced prefrontal activity, Kangaroo mother
            care, oral sucrose gel, fetal/neonatal alloimmune thrombocytopenia,
            human platelet antigens, HLA, vitamin K reductase, apolipoprotein,
            collagen 4A1, PlaNeT-2 trial, melatonin, caffeine, azithromycin,
            anakinra, haptoglobin, oligodendrocytes, implementation science.
          </p>

          <hr className="my-3" />
        </div>
      </div>
    </div>
  );
};

export default ArticleContent;
