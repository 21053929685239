import { configureStore } from "@reduxjs/toolkit";
import optionsSlice from "./slices/JournalsSlice";
import getListSlice from "./slices/getListSlice";
import fileReducer from "./slices/fileSlice";

const store = configureStore({
  reducer: {
    options: optionsSlice,
    entityData: getListSlice,
    file: fileReducer,
  },
});

export default store;
