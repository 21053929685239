import React, { useContext } from "react";
import { Button, Card } from "antd";
import ThemeContext from "../../components/ThemeContext";

const IndexPage = () => {
  const { theme } = useContext(ThemeContext);
  const cardStyle = {
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#fff",
    color: theme === "dark" ? "white" : "#001529",
    borderRadius: "10px",
    boxShadow:
      theme === "dark"
        ? "0px 0px 10px rgba(255, 255, 255, 0.2)"
        : "0px 0px 10px rgba(0, 0, 0, 0.1)",
  };
  return (
    <Card style={cardStyle}>
      <div
        className="align-center"
        style={{ display: "grid", placeItems: "center" }}
      >
        <img
          src="https://static.wixstatic.com/media/f47f77_a90fe4a8aae44e1fb3594e51ea70383e~mv2.png/v1/crop/x_11,y_0,w_529,h_707/fill/w_403,h_525,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Art_tif.png"
          alt="Art"
        />

        <h4
          className={`text-${theme === "dark" ? "white" : "dark"} font-bold mt-4`}
        >
          <em>Every Baby Counts!</em>
        </h4>
        <h4
          className={`text-${theme === "dark" ? "white" : "dark"} font-bold mt-2`}
        >
          <em>
            Each time we lose an infant, we lose an entire life and its
            potential
          </em>
        </h4>
        <Button type="primary" className="mt-4">
          More Information
        </Button>
        <div
          className="social-icon mt-4"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <span
            onClick={() =>
              window.open("https://t.me/joinchat/E_8szNmhxWTok-1m", "_blank")
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://static.wixstatic.com/media/b19da8_3d048bbd36574407aa0152f4d52119ba~mv2.png/v1/crop/x_290,y_0,w_649,h_686/fill/w_35,h_35,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/telegram_tif.png"
              alt="Telegram"
              style={{ width: "35px", height: "35px" }}
            />
          </span>

          <span
            onClick={() =>
              window.open(
                "https://www.facebook.com/groups/666523520675376/about",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://static.wixstatic.com/media/e316f544f9094143b9eac01f1f19e697.png/v1/fill/w_35,h_35,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e316f544f9094143b9eac01f1f19e697.png"
              alt="Facebook"
              style={{ width: "35px", height: "35px" }}
            />
          </span>

          <span
            onClick={() =>
              window.open(
                "https://twitter.com/ScotNeoNurses/status/1332623899377479682",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://static.wixstatic.com/media/9c4b521dd2404cd5a05ed6115f3a0dc8.png/v1/fill/w_49,h_49,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9c4b521dd2404cd5a05ed6115f3a0dc8.png"
              alt="Twitter"
              style={{ width: "35px", height: "35px" }}
            />
          </span>

          <span
            onClick={() =>
              window.open("https://www.youtube.com/user/Wix", "_blank")
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://static.wixstatic.com/media/a1b09fe8b7f04378a9fe076748ad4a6a.png/v1/fill/w_49,h_49,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a1b09fe8b7f04378a9fe076748ad4a6a.png"
              alt="Youtube"
              style={{ width: "35px", height: "35px" }}
            />
          </span>
          <span
            onClick={() =>
              window.open(
                "https://www.instagram.com/globalneosociety/",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://static.wixstatic.com/media/8d6893330740455c96d218258a458aa4.png/v1/fill/w_49,h_49,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/8d6893330740455c96d218258a458aa4.png"
              alt="Instagram"
              style={{ width: "35px", height: "35px" }}
            />
          </span>
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/global-newborn-society-b4994920b/",
                "_blank"
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://static.wixstatic.com/media/b19da8_cd6b88381f554f749686a76d1f33ad6f~mv2.png/v1/crop/x_400,y_295,w_469,h_130/fill/w_185,h_35,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/LinkedIn_tif.png"
              alt="LinkedIn"
              style={{ width: "120px", height: "35px" }}
            />
          </span>
        </div>
      </div>
    </Card>
  );
};

export default IndexPage;
