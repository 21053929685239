import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PlatformPage from "../pages/PlatformPage";
import PublisherList from "../pages/Publisher/PublisherListPage";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../components/Dashboard";
import AddPublisher from "../pages/Publisher/AddPublisher";
import Homepage from "../components/Homepage";
import ArticleType from "../pages/Journal/ArticleType";
import AddArticleType from "../pages/Journal/AddArticleType";
import Journals from "../pages/Journal/Journals";
import AccessType from "../pages/Configuration/AccessType";
import AddAccessType from "../pages/Configuration/AddAccessType";
import Subjects from "../pages/Configuration/Subjects";
import AddSubject from "../pages/Configuration/AddSubject";
import Society from "../pages/Publisher/Society";
import AddSociety from "../pages/Publisher/AddSociety";
import LicenseType from "../pages/Configuration/LicenseType";
import AddLicense from "../pages/Configuration/AddLicense";
import JournalMenu from "../pages/Journal/JournalMenu";
import PublisherLocation from "../pages/Publisher/PublisherLocation";
import AddPublisherLocation from "../pages/Publisher/AddPublisherLocation";
import EditorRoleConfig from "../pages/Configuration/EditorRoleConfig";
import AddEditorRole from "../pages/Configuration/AddEditorRole";
import EditorConfig from "../pages/Configuration/EditorConfig";
import AddEditor from "../pages/Configuration/AddEditor";
import Article from "../pages/Journal/Article";

const AppRouter = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/home" element={<Homepage />} />
      <Route
        path="/platform"
        element={
          <ProtectedRoute element={<PlatformPage />} isProtected={true} />
        }
      />
      <Route path="/publisher" element={<PublisherList />} />
      <Route path="/publisher/add-publisher" element={<AddPublisher />} />
      <Route path="/publisher/edit-publisher/:id" element={<AddPublisher />} />
      <Route path="/location" element={<PublisherLocation />} />
      <Route path="/location/add-location" element={<AddPublisherLocation />} />
      <Route path="/partner" />
      <Route path="/articleType" element={<ArticleType />} />
      <Route
        path="/articleType/add-article-type"
        element={<AddArticleType />}
      />
      <Route path="/journals" element={<Journals />} />
      <Route path="/journals/add-journal" element={<JournalMenu />} />
      <Route path="/journals/edit-journal/:id" element={<JournalMenu />} />
      <Route path="/access-type" element={<AccessType />} />
      <Route path="/access-type/add-access-type" element={<AddAccessType />} />
      <Route path="/subjects" element={<Subjects />} />
      <Route path="/subjects/add-subject" element={<AddSubject />} />
      <Route path="/society" element={<Society />} />
      <Route path="/society/add-society" element={<AddSociety />} />
      <Route path="/license-type" element={<LicenseType />} />
      <Route path="/license-type/add-license-type" element={<AddLicense />} />
      <Route path="/editor-role" element={<EditorRoleConfig />} />
      <Route path="/editor-role/add-editor-role" element={<AddEditorRole />} />
      <Route
        path="/editor-role/edit-editor-role/:id"
        element={<AddEditorRole />}
      />
      <Route path="/editor" element={<EditorConfig />} />
      <Route path="/editor/add-editor" element={<AddEditor />} />
      <Route path="/editor/edit-editor/:id" element={<AddEditor />} />
      <Route path="/articles" element={<Article />} />
    </Routes>
  );
};

export default AppRouter;
