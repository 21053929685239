import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Alert, Form, Button, Tooltip } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import themeStyles from "../../components/shared/ThemeStyles";
import CustomInput from "../../components/shared/CustomInput";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import ThemeContext from "../../components/ThemeContext";
import PageTitle from "../../components/shared/PageTitle";
import CustomDropdown from "../../components/shared/CustomDropdown";
import { useDispatch, useSelector } from "react-redux";
import { useFileHandlers, usePostData } from "../../hooks/useData";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import { fetchEntityData } from "../../store/slices/getListSlice";
import Loader from "../../components/shared/Loader";
import { setFileData } from "../../store/slices/fileSlice";

const AddPublisher = () => {
  const { id } = useParams();
  const breadcrumbItems = [
    { label: "Publishers", onClick: () => navigate("/publisher") },
    { label: id ? "Edit Publisher" : "Add Publisher" },
  ];
  const [isValidForm, setIsValidForm] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const navigate = useNavigate();
  const { mutate } = usePostData();
  const files = useSelector((state) => state.file.files);
  const { handleFileChange } = useFileHandlers();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    name: "",
    code: "",
    email: "",
    status: "",
    city: "",
    address: "",
    pincode: "",
    contact: "",
    fax: "",
    skype: "",
    state: "",
    twitter: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    country: "",
    headerLogo: "",
    footerLogo: "",
    header_logo_path: "",
    header_logo_path_accessUrl: "",
    footer_logo_path: "",
    footer_logo_path_accessUrl: "",
  });
  const { loading, data } = useSelector((state) => state.entityData);
  const fileInputKey = {
    headerLogo: 0,
    footerLogo: 0,
  };
  const fetchFileUrl = (fieldName) => {
    let file = {};
    switch (fieldName) {
      case "headerLogo":
        file = {
          fileName: formValues.header_logo_path,
          fileUrl: formValues.header_logo_path_accessUrl,
        };
        break;
      case "footerLogo":
        file = {
          fileName: formValues.footer_logo_path,
          fileUrl: formValues.footer_logo_path_accessUrl,
        };
        break;
      default:
        file = {
          fileName: formValues.header_logo_path,
          fileUrl: formValues.header_logo_path_accessUrl,
        };
    }
    return file;
  };
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const savePublisher = () => {
    if (
      formValues.name === "" ||
      formValues.code === "" ||
      formValues.contact === "" ||
      formValues.state === "" ||
      formValues.country === "" ||
      files.headerLogo?.fileName === "" ||
      files.headerLogo?.fileBase64 === "" ||
      files.footerLogo?.fileName === "" ||
      files.footerLogo?.fileBase64 === ""
    ) {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }

    const body = {
      id: id ?? 0,
      publisher_name: formValues.name,
      publisher_code: formValues.code,
      email: formValues.email,
      city: formValues.city,
      address: formValues.address,
      pincode: formValues.pincode,
      contact_no: formValues.contact,
      fax: formValues.fax,
      skype: formValues.skype,
      state: formValues.state,
      twitter_profile: formValues.twitter,
      facebook_profile: formValues.facebook,
      instagram_profile: formValues.instagram,
      linkedin_profile: formValues.linkedin,
      country_id: {
        id: formValues.country,
      },
      header_logo_path_base64: files.headerLogo?.fileBase64,
      header_logo_path_file_name: files.headerLogo?.fileName,
      footer_logo_path_base64: files.footerLogo?.fileBase64,
      footer_logo_path_file_name: files.footerLogo?.fileName,
    };

    mutate(
      {
        url: ENDPOINTS.createOrUpdatePublisher,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage(
            `Publisher ${id ? "updated " : "saved "} successfully!`
          );
          setTimeout(() => {
            navigate("/publisher");
          }, 1000);
        },
        onError: (err) => {
          console.log("Error!", err);
        },
      }
    );
  };

  const handleCancel = () => {
    setFormValues({
      name: "",
      code: "",
      email: "",
      status: "",
      city: "",
      address: "",
      pincode: "",
      contact: "",
      fax: "",
      skype: "",
      state: "",
      twitter: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      country: "",
      header_logo_path: "",
      header_logo_path_accessUrl: "",
      footer_logo_path: "",
      footer_logo_path_accessUrl: "",
    });

    navigate("/publisher");
  };

  const handleValueChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        fetchEntityData({
          entity: "CountryDetails",
          page: 0,
          size: 200,
        })
      );

      if (id) {
        const response = await dispatch(
          fetchEntityData({
            entity: "PublisherDetails",
            page: 0,
            size: 5,
            body: {
              filter: [
                {
                  id: {
                    equals: id,
                  },
                },
              ],
            },
          })
        );
        if (fetchEntityData.fulfilled.match(response)) {
          const patchFormValues = (data) => {
            const publisherData = data?.data?.data?.content?.[0];
            if (!publisherData) return;
            console.log(publisherData, "check");
            setFormValues({
              name: publisherData?.publisher_name,
              code: publisherData?.publisher_code,
              email: publisherData?.email,
              city: publisherData?.city,
              address: publisherData?.address,
              pincode: publisherData?.pincode,
              contact: publisherData?.contact_no,
              fax: publisherData?.fax,
              skype: publisherData?.skype,
              state: publisherData?.state,
              twitter: publisherData?.twitter_profile,
              facebook: publisherData?.facebook_profile,
              instagram: publisherData?.instagram_profile,
              linkedin: publisherData?.linkedin_profile,
              country: publisherData?.country_id?.id,
              header_logo_path: publisherData?.header_logo_path,
              header_logo_path_accessUrl:
                publisherData?.header_logo_path_accessUrl,
              footer_logo_path: publisherData?.footer_logo_path,
              footer_logo_path_accessUrl:
                publisherData?.footer_logo_path_accessUrl,
            });
            if (publisherData) {
              dispatch(
                setFileData({
                  fieldName: "headerLogo",
                  pathname: publisherData.header_logo_path,
                  accessurl: publisherData.header_logo_path_accessUrl,
                })
              );

              dispatch(
                setFileData({
                  fieldName: "footerLogo",
                  pathname: publisherData.footer_logo_path,
                  accessurl: publisherData.footer_logo_path_accessUrl,
                })
              );
            }
          };
          patchFormValues(response.payload);
        } else {
          console.warn(
            "Failed to fetch entity data:",
            response.error?.message || "Unknown error"
          );
        }
      }
    };
    fetchData();
  }, [dispatch, id]);

  const countryOpts = data?.map((item) => ({
    label: item?.country_name,
    value: item?.id,
  }));

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle
        title={id ? "Edit Publisher" : "Add Publisher"}
        color={cardStyle.color}
      />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}

      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Name"
                type="text"
                required
                name="name"
                value={formValues.name}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Code"
                type="text"
                required
                name="code"
                value={formValues.code}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Email"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="City"
                type="text"
                name="city"
                value={formValues.city}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-8">
              <CustomInput
                label="Address"
                type="text"
                name="address"
                value={formValues.address}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Pincode"
                type="number"
                name="pincode"
                value={formValues.pincode}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Contact No."
                type="number"
                name="contact"
                required
                value={formValues.contact}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Fax"
                type="number"
                name="fax"
                value={formValues.fax}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Skype"
                type="number"
                name="skype"
                value={formValues.skype}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="State"
                type="text"
                name="state"
                required
                value={formValues.state}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Twitter Profile"
                type="text"
                name="twitter"
                value={formValues.twitter}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Facebook Profile"
                type="text"
                name="facebook"
                value={formValues.facebook}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Instagram Profile"
                type="text"
                name="instagram"
                value={formValues.instagram}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Linkedin Profile"
                type="text"
                name="linkedin"
                value={formValues.linkedin}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomDropdown
                label="Country"
                required
                name="country"
                value={formValues.country}
                onChange={handleValueChange}
                theme={theme}
                options={countryOpts}
              />
            </div>
            <div className="col-md-4">
              {files.headerLogo?.fileError && (
                <div style={{ color: "red" }}>
                  {files.headerLogo?.fileError}
                </div>
              )}
              <CustomInput
                key={fileInputKey.headerLogo}
                label={
                  <span className="flex">
                    Header Logo{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg or .png;">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                required
                type="file"
                name="headerLogo"
                value={formValues.headerLogo}
                onChange={(e) =>
                  handleFileChange(
                    e,
                    ["image/jpeg", "image/png"],
                    1,
                    null,
                    "headerLogo"
                  )
                }
                theme={theme}
                fileUrl={() => fetchFileUrl("headerLogo")}
              />
            </div>
            <div className="col-md-4">
              {files.footerLogo?.fileError && (
                <div style={{ color: "red" }}>
                  {files.footerLogo?.fileError}
                </div>
              )}
              <CustomInput
                key={fileInputKey.footerLogo}
                label={
                  <span className="flex">
                    Footer Logo{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg or .png;">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                required
                type="file"
                name="footerLogo"
                value={formValues.footerLogo}
                onChange={(e) =>
                  handleFileChange(
                    e,
                    ["image/jpeg", "image/png"],
                    1,
                    null,
                    "footerLogo"
                  )
                }
                theme={theme}
                fileUrl={() => fetchFileUrl("footerLogo")}
              />
            </div>
          </div>
          <div className="mt-2 text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={savePublisher}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddPublisher;
