import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { useFileHandlers, usePostData } from "../../hooks/useData";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntityData } from "../../store/slices/getListSlice";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import PageTitle from "../../components/shared/PageTitle";
import { Alert, Button, Card, Form, Tooltip } from "antd";
import CustomInput from "../../components/shared/CustomInput";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import CustomEditor from "../../components/shared/CustomEditor";
import { setFileData } from "../../store/slices/fileSlice";

const AddEditor = () => {
  const { id } = useParams();
  const breadcrumbItems = [
    { label: "Editor", onClick: () => navigate("/editor") },
    { label: id ? "Edit Editor" : "Add Editor" },
  ];
  const [isValidForm, setIsValidForm] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const navigate = useNavigate();
  const { mutate } = usePostData();
  const dispatch = useDispatch();
  const { handleFileChange } = useFileHandlers();
  const files = useSelector((state) => state.file.files);
  const [formValues, setFormValues] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    url: "",
    editorImg: "",
    editor_image_path: "",
    editor_image_path_accessUrl: "",
    orcId: "",
    phone: "",
    mailingAddress: "",
    qualification: "",
    designation: "",
    department: "",
    institute: "",
    biography: "",
    fieldsOfInterest: "",
    recentPublications: "",
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleTextChange = (name, updatedData) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: updatedData,
    }));
  };

  const handleCancel = () => {
    setFormValues({
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      url: "",
      editorImg: "",
      orcId: "",
      phone: "",
      mailingAddress: "",
      qualification: "",
      designation: "",
      department: "",
      institute: "",
      biography: "",
      fieldsOfInterest: "",
      recentPublications: "",
    });
    navigate("/editor");
  };

  const fileInputKey = {
    editorImg: 0,
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await dispatch(
          fetchEntityData({
            entity: "EditorDetails",
            page: 0,
            size: 5,
            body: {
              filter: [
                {
                  id: {
                    equals: id,
                  },
                },
              ],
            },
          })
        );
        if (fetchEntityData.fulfilled.match(response)) {
          const patchFormValues = (data) => {
            const editorData = data?.data?.data?.content?.[0];
            setFormValues({
              title: editorData?.title,
              firstName: editorData?.first_name,
              middleName: editorData?.middle_name,
              lastName: editorData?.last_name,
              email: editorData?.email,
              url: editorData?.url,
              editor_image_path: editorData?.editor_image_path,
              editor_image_path_accessUrl:
                editorData?.editor_image_path_accessUrl,
              orcId: editorData?.orcid,
              phone: editorData?.phone,
              mailingAddress: editorData?.mailing_address,
              qualification: editorData?.qualifications,
              designation: editorData?.designation,
              department: editorData?.department,
              institute: editorData?.institute,
              biography: editorData?.biography,
              fieldsOfInterest: editorData?.field_of_interest,
              recentPublications: editorData?.recent_publications,
            });
            if (
              editorData?.editor_image_path_file_name &&
              editorData?.editor_image_path_accessUrl
            ) {
              dispatch(
                setFileData({
                  fieldName: "editorImg",
                  pathName: editorData?.editor_image_path_file_name,
                  accessUrl: editorData?.editor_image_path_accessUrl,
                })
              );
            }
          };
          patchFormValues(response.payload);
        } else {
          console.warn(
            "Failed to fetch entity data:",
            response.error?.message || "Unknown error"
          );
        }
      }
    };
    fetchData();
  }, [dispatch, id]);

  const fetchFileUrl = (fieldName) => {
    let file = {};
    if (fieldName === "editorImg") {
      file = {
        fileName: formValues.editor_image_path,
        fileUrl: formValues.editor_image_path_accessUrl,
      };
      return file;
    }
  };

  const saveEditor = () => {
    if (formValues.firstName === "" || formValues.lastName === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
    const body = {
      id: id ?? 0,
      title: formValues.title,
      first_name: formValues.firstName,
      middle_name: formValues.middleName,
      last_name: formValues.lastName,
      email: formValues.email,
      url: formValues.url,
      orcid: formValues.orcId,
      phone: formValues.phone,
      mailing_address: formValues.mailingAddress,
      qualifications: formValues.qualification,
      designation: formValues.designation,
      department: formValues.department,
      institute: formValues.institute,
      biography: formValues.biography,
      field_of_interest: formValues.fieldsOfInterest,
      recent_publications: formValues.recentPublications,
      editor_image_path_base64: files.editorImg?.fileBase64 || "",
      editor_image_path_file_name: files.editorImg?.fileName || "",
    };

    mutate(
      {
        url: ENDPOINTS.saveOrUpdateEditor,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage(
            `Editor ${id ? "updated" : "saved"} successfully!`
          );
          setTimeout(() => {
            navigate("/editor");
          }, 1000);
        },
        onError: (err) => {
          console.log("Error!", err);
        },
      }
    );
  };

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle
        title={id ? "Edit Editor" : "Add Editor"}
        color={cardStyle.color}
      />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Title"
                type="text"
                name="title"
                value={formValues.title}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="First Name"
                type="text"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
                theme={theme}
                required
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Middle Name"
                type="text"
                name="middleName"
                value={formValues.middleName}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Last Name"
                type="text"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
                theme={theme}
                required
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Email"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Url"
                type="text"
                name="url"
                value={formValues.url}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-8">
              {files.editorImg?.fileError && (
                <div style={{ color: "red" }}>{files.editorImg?.fileError}</div>
              )}
              <CustomInput
                key={fileInputKey.editorImg}
                label={
                  <span className="flex">
                    Editor Image{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg, .png;">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                type="file"
                name="editorImg"
                value={formValues.editorImg}
                onChange={(e) =>
                  handleFileChange(
                    e,
                    ["image/jpg", "image/jpeg", "image/png"],
                    1,
                    null,
                    "editorImg"
                  )
                }
                theme={theme}
                fileUrl={() => fetchFileUrl("editorImg")}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="ORCiD"
                type="text"
                name="orcId"
                value={formValues.orcId}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Phone"
                type="number"
                name="phone"
                value={formValues.phone}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Mailing Address"
                type="text"
                name="mailingAddress"
                value={formValues.mailingAddress}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Qualification"
                type="text"
                name="qualification"
                value={formValues.qualification}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-4">
              <CustomInput
                label="Designation"
                type="text"
                name="designation"
                value={formValues.designation}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Department"
                type="text"
                name="department"
                value={formValues.department}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Institute"
                type="text"
                name="institute"
                value={formValues.institute}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Biography"
                name="biography"
                value={formValues.biography}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Fields Of Interest"
                name="fieldsOfInterest"
                value={formValues.fieldsOfInterest}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Recent Publications"
                name="recentPublications"
                value={formValues.recentPublications}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveEditor}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddEditor;
