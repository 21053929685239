import React, { useContext } from "react";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../ThemeContext";

const CustomBreadcrumb = ({ items }) => {
  const navigate = useNavigate();
  // const MenuItem = getVisibleMenuItems();
  const { theme } = useContext(ThemeContext);
  const separatorStyle = {
    color: theme === "dark" ? "white" : "black",
  };
  const breadcrumbItems = [
    {
      title: (
        <span
          className="flex items-center transition-all duration-150 hover:text-blue-700 hover:font-semibold hover:scale-105"
          style={{ color: theme === "dark" ? "white" : "black" }}
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </span>
      ),
    },
    ...items.map((item, index) => {
      // const menuItem = !item.link
      //   ? MenuItem?.find((menu) => menu.title === item.label)
      //   : MenuItem?.find((menu) => menu.path === item.link);
      const isLast = index === items.length - 1;

      return {
        title: (
          <span
            className={`flex items-center ${!isLast ? "transition-all duration-150 hover:text-blue-700 hover:font-semibold hover:scale-105" : ""}`}
            style={{ color: theme === "dark" ? "white" : "black" }}
            onClick={() => {
              if (!isLast) {
                if (item.onClick) {
                  item.onClick();
                } else if (item.link) {
                  navigate(item.link);
                }
              }
            }}
          >
            <span>{item.label}</span>
          </span>
        ),
        key: index,
      };
    }),
  ];

  return (
    <div>
      <Breadcrumb
        separator={<span style={separatorStyle}>/</span>}
        items={breadcrumbItems}
      />
    </div>
  );
};

export default CustomBreadcrumb;
