import React, { useEffect, useState } from 'react';
import { Select, Button, Space, Form } from 'antd';
import {
  filterConditionsByType,
  filterOperatorLabels,
} from './filterConditionsConfig';
import CustomInput from '../CustomInput';
import CustomDatePicker from '../customDatePicker';
import CustomRangePickerRangePicker from '../customRangePicker';
const { Option } = Select;

const FilterComponent = ({ initialValue, field, onApply }) => {
  const [condition, setCondition] = useState(null);
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState({ condition: '', value: '' });

  useEffect(() => {
    if (initialValue) {
      setCondition(initialValue?.condition);
      setValue(initialValue?.value);
    }
  }, [initialValue]);

  const validate = () => {
    let valid = true;
    const newErrors = { condition: '', value: '' };

    if (!condition) {
      newErrors.condition = 'Please select a condition.';
      valid = false;
    }

    if (
      condition &&
      !['is-null', 'is-not-null'].includes(condition) &&
      (!value || value.length === 0)
    ) {
      newErrors.value = 'Please enter a value.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleApply = () => {
    if (validate()) {
      onApply({ field, condition, value });
    }
  };

  const handleCondtion = (value) => {
    setCondition(value);
    if (value == 'between') {
      setValue(['', '']);
    } else {
      setValue('');
    }
  };

  const renderInput = () => {
    switch (condition) {
      case 'between':
        return field?.type === 'date' ? (
          <CustomRangePickerRangePicker
            value={value}
            format={'DD/MM/YYYY'}
            onChange={(dates) => setValue(dates)}
          />
        ) : (
          <Space.Compact>
            <CustomInput
              style={{ width: '50%' }}
              value={value[0]}
              placeholder="Minimum Value"
              onChange={(e) => setValue([e.target.value, value[1]])}
            />
            <CustomInput
              style={{ width: '50%' }}
              value={value[1]}
              placeholder="Maximum Value"
              onChange={(e) => setValue([value[0], e.target.value])}
            />
          </Space.Compact>
        );
      case 'in':
      case 'not-in':
        return field?.type === 'date' ? (
          <CustomDatePicker
            value={value}
            format={'DD/MM/YYYY'}
            onChange={(dates) => setValue(dates)}
          />
        ) : (
          <CustomInput
            value={value}
            type={field?.type === 'number' ? 'number' : 'text'}
            placeholder="Enter values separated by comma"
            onChange={(e) => setValue(e.target.value.split(','))}
          />
        );
      case 'is-null':
      case 'is-not-null':
        return null;
      default:
        return field?.type === 'date' ? (
          <CustomDatePicker
            value={value}
            format={'DD/MM/YYYY'}
            onChange={(dates) => setValue(dates)}
          />
        ) : (
          <CustomInput
            value={value}
            placeholder="Enter value"
            onChange={(e) => setValue(e.target.value)}
          />
        );
    }
  };

  return (
    <div className="pt-2 p-4">
      <Space direction="vertical" className="w-full space-y-4">
        <Form layout="vertical">
          <Form.Item
            label="Condition"
            validateStatus={errors.condition ? 'error' : ''}
            help={errors.condition}
          >
            <Select
              showSearch
              placeholder="Select Condition"
              value={condition}
              onChange={(value) => handleCondtion(value)}
              className="w-full"
            >
              {filterConditionsByType[field?.type].map((cond) => (
                <Option key={cond} value={cond}>
                  {filterOperatorLabels[cond]}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {condition && !['is-null', 'is-not-null'].includes(condition) && (
            <Form.Item
              label="Conditional value"
              validateStatus={errors.value ? 'error' : ''}
              help={errors.value}
            >
              {renderInput()}
            </Form.Item>
          )}
          <Button
            type="primary"
            onClick={handleApply}
            className="w-full rounded-md py-2"
          >
            Apply Filter
          </Button>
        </Form>
      </Space>
    </div>
  );
};

export default FilterComponent;
