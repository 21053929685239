import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Form } from "antd";

const CustomEditor = ({
  label,
  name,
  data,
  onChange,
  theme,
  required,
  value,
}) => {
  const darkThemeStyles = {
    color: "white",
    backgroundColor: "#333",
  };

  const lightThemeStyles = {
    color: "black",
    backgroundColor: "white",
  };

  const currentThemeStyles =
    theme === "dark" ? darkThemeStyles : lightThemeStyles;

  return (
    <Form.Item
      label={<span style={{ color: currentThemeStyles.color }}>{label}</span>}
      required={required}
      labelCol={{ span: 24 }}
      style={{ marginBottom: "16px" }}
    >
      <div className="editor-container">
        <CKEditor
          editor={ClassicEditor}
          data={value}
          onChange={(event, editor) => {
            const updatedData = editor.getData();
            onChange(name, updatedData);
          }}
          config={{
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "|",
              "blockQuote",
              "insertTable",
              "mediaEmbed",
              "undo",
              "redo",
            ],
            height: "250px",
          }}
        />
      </div>
    </Form.Item>
  );
};

export default CustomEditor;
