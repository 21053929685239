import React, { useContext, useState } from "react";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Form } from "antd";
import CustomInput from "../../components/shared/CustomInput";
import { usePostData } from "../../hooks/useData";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import PageTitle from "../../components/shared/PageTitle";

const AddAccessType = () => {
  const breadcrumbItems = [
    { label: "Access Type", onClick: () => navigate("/access-type") },
    { label: "Add Access Type" },
  ];
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const [formValues, setFormValues] = useState({ accessType: "" });
  const [isValidForm, setIsValidForm] = useState(true);
  const { mutate } = usePostData();
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleCancel = () => {
    setFormValues({
      accessType: "",
    });
    navigate("/access-type");
  };

  const saveAcessType = () => {
    if (formValues.accessType === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }

    const body = {
      id: 0,
      access_type: formValues.accessType,
      active_status: true,
    };

    mutate(
      {
        url: ENDPOINTS.saveOrUpdateAccessTypes,
        data: body,
      },
      {
        onSuccess: (response) => {
          console.log("Access type saved successfully:", response);
          showSuccessMessage("Access type saved successfully!");
          setTimeout(() => {
            navigate("/access-type");
          }, 1000);
        },
        onError: (err) => {
          console.error("Error saving access type:", err);
        },
      }
    );
  };
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Add Access Type" color={cardStyle.color} />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label="Access"
                type="text"
                required
                name="accessType"
                value={formValues.accessType}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveAcessType}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddAccessType;
