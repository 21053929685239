import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ENDPOINTS } from "../../services/endpoints";
import axios from "axios";

export const fetchCategoryOptions = createAsyncThunk(
  "options/fetchCategoryOptions",
  async () => {
    const url = `${ENDPOINTS.getEntityData}/SubjectDetails?page=0&size=2000`;
    const field = "id,subject_name,subject_type";
    const body = field ? { field } : {};
    const response = await axios.post(url, body);
    if (response?.status !== 200) {
      throw new Error(`Error: ${response?.status} - ${response?.message}`);
    }
    return response.data;
  }
);

export const fetchAccessTypeOptions = createAsyncThunk(
  "options/fetchAccessTypeOptions",
  async () => {
    const url = `${ENDPOINTS.getEntityData}/AccessType?page=0&size=2000`;
    const field = "id,access_type";
    const body = field ? { field } : {};
    const response = await axios.post(url, body);
    if (response?.status !== 200) {
      throw new Error(`Error: ${response?.status} - ${response?.message}`);
    }
    return response.data;
  }
);

export const fetchSocietyOptions = createAsyncThunk(
  "options/fetchSocietyOptions",
  async () => {
    const url = `${ENDPOINTS.getEntityData}/SocietyDetails?page=0&size=2000`;
    const field = "id,society_title";
    const body = field ? { field } : {};
    const response = await axios.post(url, body);
    if (response?.status !== 200) {
      throw new Error(`Error: ${response?.status} - ${response?.message}`);
    }
    return response.data;
  }
);

export const fetchLicenseTypeOptions = createAsyncThunk(
  "options/fetchLicenseTypeOptions",
  async () => {
    const url = `${ENDPOINTS.getEntityData}/LicenseType?page=0&size=2000`;
    const field = "id,license_type";
    const body = field ? { field } : {};
    const response = await axios.post(url, body);
    if (response?.status !== 200) {
      throw new Error(`Error: ${response?.status} - ${response?.message}`);
    }
    return response.data;
  }
);

const optionsSlice = createSlice({
  name: "options",
  initialState: {
    subjectDetailsOptions: [],
    accessTypeOptions: [],
    societyDetailsOptions: [],
    licenseTypeOptions: [],
    loadingSubjectDetails: false,
    loadingAccessType: false,
    loadingSocietyDetails: false,
    loadingLicenseType: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoryOptions.pending, (state) => {
        state.loadingSubjectDetails = true;
        state.error = null;
      })
      .addCase(fetchCategoryOptions.fulfilled, (state, action) => {
        state.loadingSubjectDetails = false;
        state.subjectDetailsOptions = action.payload?.data?.content;
      })
      .addCase(fetchCategoryOptions.rejected, (state, action) => {
        state.loadingSubjectDetails = false;
        state.error = action.payload;
      })
      .addCase(fetchAccessTypeOptions.pending, (state) => {
        state.loadingAccessType = true;
        state.error = null;
      })
      .addCase(fetchAccessTypeOptions.fulfilled, (state, action) => {
        state.loadingAccessType = false;
        state.accessTypeOptions = action.payload?.data?.content;
      })
      .addCase(fetchAccessTypeOptions.rejected, (state, action) => {
        state.loadingAccessType = false;
        state.error = action.payload;
      })
      .addCase(fetchSocietyOptions.pending, (state) => {
        state.loadingSocietyDetails = true;
        state.error = null;
      })
      .addCase(fetchSocietyOptions.fulfilled, (state, action) => {
        state.loadingSocietyDetails = false;
        state.societyDetailsOptions = action.payload?.data?.content;
      })
      .addCase(fetchSocietyOptions.rejected, (state, action) => {
        state.loadingSocietyDetails = false;
        state.error = action.payload;
      })
      .addCase(fetchLicenseTypeOptions.pending, (state) => {
        state.loadingLicenseType = true;
        state.error = null;
      })
      .addCase(fetchLicenseTypeOptions.fulfilled, (state, action) => {
        state.loadingLicenseType = false;
        state.licenseTypeOptions = action.payload?.data?.content;
      })
      .addCase(fetchLicenseTypeOptions.rejected, (state, action) => {
        state.loadingLicenseType = false;
        state.error = action.payload;
      });
  },
});

export default optionsSlice.reducer;
