import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ENDPOINTS } from "../../services/endpoints";

export const fetchEntityData = createAsyncThunk(
  "data/fetchEntityData",
  async ({ entity, page = 1, size = 10, body = {} }, { rejectWithValue }) => {
    try {
      const url = `${ENDPOINTS.getEntityData}/${entity}?page=${page}&size=${size}`;
      const response = await axios.post(url, body);
      if (response?.status !== 200) {
        throw new Error(`Error: ${response?.status} - ${response?.statusText}`);
      }
      return { data: response.data, entity };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const getListSlice = createSlice({
  name: "entityData",
  initialState: {
    data: [],
    allData: [],
    status: "idle",
    error: null,
    pageIndex: 0,
    limit: 5,
    totalItems: 0,
    totalPages: 1,
    sorting: [],
    filters: [],
    loading: false,
  },
  reducers: {
    setEntityDataPageIndex: (state, action) => {
      state.pageIndex = action.payload;
    },
    setEntityDataPageSize: (state, action) => {
      state.limit = action.payload;
    },
    setEntityDataSorting: (state, action) => {
      state.sorting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntityData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEntityData.fulfilled, (state, action) => {
        state.loading = false;
        const dataContent = action.payload?.data?.data?.content || [];
        state.data = dataContent;
        state.totalItems = action.payload?.data?.data?.totalElements || 0;
      })
      .addCase(fetchEntityData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setEntityDataPageIndex,
  setEntityDataPageSize,
  setEntityDataSorting,
} = getListSlice.actions;
export default getListSlice.reducer;
