import axios from 'axios';
// import { clearToken } from '../store/slices/authSlice';
import store from '../store/store';
const apiClient = axios.create();

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // store.dispatch(clearToken());
      window.location.href = '/Publisher';
    }
    return Promise.reject(error);
  }
);

export default apiClient;
