import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthorized } from '../utils/auth';

const ProtectedRoute = ({ element, isProtected }) => {
  return isProtected && !isAuthorized() ? (
    <Navigate to="/publisher" replace />
  ) : (
    element
  );
};

export default ProtectedRoute;
