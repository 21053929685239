import React from 'react';
import { DatePicker } from 'antd';
import 'antd/dist/reset.css';

const CustomDatePicker = ({ options, ...props }) => {
  return (
    <DatePicker
      style={{ height: '32px' }}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      {...props}
    />
  );
};

export default CustomDatePicker;
