import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { usePostData } from "../../hooks/useData";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import PageTitle from "../../components/shared/PageTitle";
import { Alert, Button, Card, Form } from "antd";
import CustomInput from "../../components/shared/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchEntityData } from "../../store/slices/getListSlice";
import Loader from "../../components/shared/Loader";

const AddEditorRole = () => {
  const { id } = useParams();
  const breadcrumbItems = [
    { label: "Editor Role", onClick: () => navigate("/editor-role") },
    { label: id ? "Edit Editor Role" : "Add Editor Role" },
  ];
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const [formValues, setFormValues] = useState({
    role: "",
  });
  const [isValidForm, setIsValidForm] = useState(true);
  const { mutate } = usePostData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const { loading } = useSelector((state) => state.entityData);

  const handleCancel = () => {
    setFormValues({
      role: "",
    });
    navigate("/editor-role");
  };

  const saveRole = () => {
    if (formValues.role === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
    const body = {
      id: id ?? 0,
      editor_role: formValues.role,
      active_status: true,
    };

    mutate(
      {
        url: ENDPOINTS.saveOrUpdateEditorRoles,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage(
            `Editor Role ${id ? "updated " : "saved "} successfully!`
          );
          setTimeout(() => {
            navigate("/editor-role");
          }, 1000);
        },
        onError: (err) => {
          console.log("Error!", err);
        },
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const response = await dispatch(
          fetchEntityData({
            entity: "EditorRoles",
            page: 0,
            size: 5,
            body: {
              filter: [
                {
                  id: {
                    equals: id,
                  },
                },
              ],
            },
          })
        );
        if (fetchEntityData.fulfilled.match(response)) {
          const patchFormValues = (data) => {
            setFormValues({
              role: data?.data?.data?.content?.[0]?.editor_role,
            });
          };
          patchFormValues(response.payload);
        } else {
          console.warn(
            "Failed to fetch entity data:",
            response.error?.message || "Unknown error"
          );
        }
      }
    };
    fetchData();
  }, [dispatch, id]);

  if (loading) {
    <Loader />;
  }

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle
        title={id ? "Edit Editor Role" : "Add Editor Role"}
        color={cardStyle.color}
      />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Role"
                type="text"
                required
                name="role"
                value={formValues.role}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveRole}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddEditorRole;
