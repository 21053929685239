import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import useCustomColumnSearch from "../../components/shared/CustomColumnSearch";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEntityData,
  setEntityDataPageIndex,
  setEntityDataPageSize,
  setEntityDataSorting,
} from "../../store/slices/getListSlice";
import Loader from "../../components/shared/Loader";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import PageTitle from "../../components/shared/PageTitle";
import { Button, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Table from "../../components/Table";

const LicenseType = () => {
  const breadcrumbItems = [{ label: "License Type" }];
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { cardStyle } = themeStyles(theme);
  const { getColumnSearchProps } = useCustomColumnSearch();
  const handleEdit = () => {};
  const handleAddNew = () => {
    navigate("/license-type/add-license-type");
  };
  const dispatch = useDispatch();
  const { loading, limit, pageIndex, data, totalItems } = useSelector(
    (state) => state.entityData
  );

  const columns = [
    {
      title: "License Type",
      dataIndex: "license_type",
      key: "license_type",
      ...getColumnSearchProps("license_type"),
      sorter: true,
    },
    {
      title: "License URL",
      dataIndex: "license_url",
      key: "license_url",
      ...getColumnSearchProps("license_url"),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "active_status",
      key: "active_status",
      filters: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      onFilter: (value, record) => record.active_status === value,
      render: (value) => (value ? "Active" : "Inactive"),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-sm btn-primary-outline"
          style={{ color: theme === "dark" ? "white" : "black" }}
          onClick={() => handleEdit(record)}
        >
          Edit
        </button>
      ),
    },
  ];

  const handleTableChange = ({ pageIndex, pageSize, sortBy }) => {
    dispatch(setEntityDataPageIndex(pageIndex));
    dispatch(setEntityDataPageSize(pageSize));
    dispatch(setEntityDataSorting(sortBy));
  };

  useEffect(() => {
    dispatch(
      fetchEntityData({
        entity: "LicenseType",
        page: pageIndex,
        size: limit,
      })
    );
  }, [dispatch, pageIndex, limit]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="License Type" color={cardStyle.color} />
      <Card className="mt-3" style={cardStyle}>
        <Button
          type="primary"
          className="mb-4"
          style={{ float: "right" }}
          onClick={handleAddNew}
          icon={<PlusOutlined />}
        >
          Add
        </Button>
        <Table
          columns={columns}
          data={data}
          manualPagination={false}
          initialPageIndex={pageIndex}
          initialPageSize={limit}
          totalItems={totalItems}
          onTableChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default LicenseType;
