import React, { useEffect } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import useCustomColumnSearch from "../../components/shared/CustomColumnSearch";
import themeStyles from "../../components/shared/ThemeStyles";
import ThemeContext from "../../components/ThemeContext";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEntityData,
  setEntityDataPageIndex,
  setEntityDataPageSize,
  setEntityDataSorting,
} from "../../store/slices/getListSlice";
import Loader from "../../components/shared/Loader";
import PageTitle from "../../components/shared/PageTitle";
import Table from "../../components/Table";

const PublisherListPage = () => {
  // const [showForm, setshowForm] = useState(false);
  const breadcrumbItems = [{ label: "Publisher" }];
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { cardStyle } = themeStyles(theme);
  const { getColumnSearchProps } = useCustomColumnSearch();
  const dispatch = useDispatch();

  const { loading, limit, pageIndex, data, totalItems } = useSelector(
    (state) => state.entityData
  );
  const columns = [
    {
      title: "Publisher Name",
      dataIndex: "publisher_name",
      key: "publisher_name",
      width: "30%",
      ...getColumnSearchProps("publisher_name"),
      sorter: true,
    },
    {
      title: "Publisher Code",
      dataIndex: "publisher_code",
      key: "publisher_code",
      width: "20%",
      ...getColumnSearchProps("publisher_code"),
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      ...getColumnSearchProps("email"),
      sorter: true,
    },
    // {
    //   title: "Status",
    //   dataIndex: "active_status",
    //   key: "active_status",
    //   filters: [
    //     { text: "Active", value: true },
    //     { text: "Inactive", value: false },
    //   ],
    //   onFilter: (value, record) => record.active_status === value,
    //   render: (value) => (value ? "Active" : "Inactive"),
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-sm btn-primary-outline"
          style={{ color: theme === "dark" ? "white" : "black" }}
          onClick={() => handleEditPublisher(record)}
        >
          Edit
        </button>
      ),
    },
  ];

  const handleTableChange = ({ pageIndex, pageSize, sortBy }) => {
    dispatch(setEntityDataPageIndex(pageIndex));
    dispatch(setEntityDataPageSize(pageSize));
    dispatch(setEntityDataSorting(sortBy));
  };

  const handleEditPublisher = (record) => {
    navigate(`/publisher/edit-publisher/${record.id}`);
  };

  // const handleAddNew = () => {
  //   navigate("/publisher/add-publisher");
  // };

  useEffect(() => {
    dispatch(
      fetchEntityData({
        entity: "PublisherDetails",
        page: pageIndex,
        size: limit,
      })
    );
  }, [dispatch, pageIndex, limit]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Publishers" color={cardStyle.color} />
      <div>
        {/* <FilterTags filterList={filters} handleFilters={handleFilters} /> */}

        <Card className="mt-3" style={cardStyle}>
          {/* <Button
            type="primary"
            className="mb-4"
            style={{ float: "right" }}
            onClick={handleAddNew}
            icon={<PlusOutlined />}
          >
            Add
          </Button> */}
          <Table
            columns={columns}
            data={data}
            manualPagination={false}
            initialPageIndex={pageIndex}
            initialPageSize={limit}
            totalItems={totalItems}
            onTableChange={handleTableChange}
          />
        </Card>
      </div>
    </>
  );
};

export default PublisherListPage;
