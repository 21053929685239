const BASE_URL = '';
const API_ENDPOINTS = {
  caseDetails: {
    list: `${BASE_URL}/case/getpubliser`,
  },
  auth: {
    generateOAuth2Token: `${BASE_URL}/login/generateOAuth2Token`,
  },
};

export default API_ENDPOINTS;
