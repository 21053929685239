import React, { useContext } from "react";
import { Table as AntTable, Pagination as AntPagination } from "antd";
import ThemeContext from "../ThemeContext";
import "./TableStyle.css"; 

const Table = ({
  data,
  columns,
  manualPagination = false,
  initialPageIndex = 0,
  initialPageSize = 5,
  totalItems,
  onTableChange,
}) => {
  const { theme } = useContext(ThemeContext);
  const isDarkTheme = theme === "dark";

  // const paginationStyle = {
  //   backgroundColor: isDarkTheme ? "#444" : "#f0f0f0",
  //   color: isDarkTheme ? "#fff" : "#000",
  // };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortBy = sorter.order
      ? [{ id: sorter.columnKey, desc: sorter.order === "descend" }]
      : [];

    if (onTableChange) {
      onTableChange({
        pageIndex: pagination.current - 1,
        pageSize: pagination.pageSize,
        sortBy,
      });
    }
  };

  const paginationProps = {
    current: initialPageIndex + 1,
    pageSize: initialPageSize,
    total: totalItems,
    showSizeChanger: true,
    pageSizeOptions: ["5", "10", "20", "50"],
    onChange: (page, pageSize) => {
      onTableChange({ pageIndex: page - 1, pageSize });
    },
    // style: paginationStyle,
  };

  return (
    <>
      <AntTable
        columns={columns.map((col) => ({
          ...col,
          sorter: col.sort,
          className: isDarkTheme ? "dark-column" : "light-column",
          render: (text, record) =>
            col.render ? col.render(text, record) : text,
        }))}
        dataSource={data}
        pagination={!manualPagination ? paginationProps : false}
        onChange={handleTableChange}
        rowClassName={() => (isDarkTheme ? "dark-row" : "light-row")}
        rowKey={(record) => record?.id}
      />
      {manualPagination && (
        <AntPagination
          {...paginationProps}
          onChange={(page, pageSize) => {
            onTableChange({ pageIndex: page - 1, pageSize });
          }}
        />
      )}
    </>
  );
};

export default Table;
