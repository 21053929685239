import React, { useContext, useState } from "react";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { usePostData } from "../../hooks/useData";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import { Alert, Button, Card, Form } from "antd";
import CustomDropdown from "../../components/shared/CustomDropdown";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import CustomInput from "../../components/shared/CustomInput";
import PageTitle from "../../components/shared/PageTitle";

const AddSubject = () => {
  const breadcrumbItems = [
    { label: "Subjects", onClick: () => navigate("/subjects") },
    { label: "Add Subject" },
  ];
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const [formValues, setFormValues] = useState({
    subjectType: "",
    subjectName: "",
    featured: false,
  });
  const [isValidForm, setIsValidForm] = useState(true);
  const { mutate } = usePostData();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleCancel = () => {
    setFormValues({
      subjectType: "",
      subjectName: "",
      featured: false,
    });
    navigate("/subjects");
  };

  const saveSubject = () => {
    if (formValues.subjectType === "" || formValues.subjectName === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
    const body = {
      id: 0,
      subject_type: formValues.subjectType,
      subject_name: formValues.subjectName,
      featured: formValues.featured,
      active_status: true,
    };

    mutate(
      {
        url: ENDPOINTS.saveOrUpdateSubjects,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage("Subject saved successfully!");
          setTimeout(() => {
            navigate("/subjects");
          }, 1000);
        },
        onError: (err) => {
          console.log("Error!", err);
        },
      }
    );
  };

  const handleValueChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const subjectTypeOpts = [
    { label: "Category", value: "Category" },
    { label: "Specialty", value: "Specialty" },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Add Subject" color={cardStyle.color} />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-4">
              <CustomDropdown
                label="Subject Type"
                required
                name="subjectType"
                value={formValues.subjectType}
                onChange={handleValueChange}
                theme={theme}
                options={subjectTypeOpts}
              />
            </div>
            <div className="col-md-4">
              <CustomInput
                label="Subject Name"
                type="text"
                required
                name="subjectName"
                value={formValues.subjectName}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-4 mt-4">
              <CustomInput
                label="Featured"
                type="checkbox"
                name="featured"
                value={formValues.featured}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveSubject}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddSubject;
