import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, Form, Radio, Table, Tooltip } from "antd";
import CustomDropdown from "../../components/shared/CustomDropdown";
import { DeleteOutline } from "@mui/icons-material";
import CustomInput from "../../components/shared/CustomInput";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import CustomEditor from "../../components/shared/CustomEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccessTypeOptions,
  fetchCategoryOptions,
  fetchLicenseTypeOptions,
  fetchSocietyOptions,
} from "../../store/slices/JournalsSlice";
import Loader from "../../components/shared/Loader";
import { clearFileErrors, setFileData } from "../../store/slices/fileSlice";
import { useFileHandlers, usePostData } from "../../hooks/useData";
import { ENDPOINTS } from "../../services/endpoints";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../components/shared/MessageUtils";
import { fetchEntityData } from "../../store/slices/getListSlice";

const AddJournal = ({ onSaveJournal }) => {
  const { id } = useParams();
  const [isValidForm, setIsValidForm] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle, tableStyle } = themeStyles(theme);
  const navigate = useNavigate();
  const { mutate } = usePostData();
  const { handleFileChange } = useFileHandlers();
  const [formValues, setFormValues] = useState({
    accessType: "",
    licenseType: "",
    journalCode: "",
    issn: "",
    onlineIssn: "",
    doi: "",
    journalTitle: "",
    journalSubtitle: "",
    journalShortName: "",
    journalURL: "",
    readerShip: "",
    lensURL: "",
    journalCoverImg: "",
    journal_cover_image_path: "",
    journal_cover_image_path_accessUrl: "",
    journalCoverImg1: "",
    journal_cover_image_one_path: "",
    journal_cover_image_one_path_accessUrl: "",
    journalCoverImg2: "",
    journal_cover_image_two_path: "",
    journal_cover_image_two_path_accessUrl: "",
    journalCoverImg3: "",
    journal_cover_image_three_path: "",
    journal_cover_image_three_path_accessUrl: "",
    twitterProfile: "",
    facebookProfile: "",
    instagramProfile: "",
    youtubeUrl: "",
    linkedIn: "",
    plumXCode: "",
    email: "",
    articleOfMonth: false,
    featured: false,
    downloadEnabled: false,
    accessible: true,
    availableOAI: false,
    availableRepec: false,
    numIssues: "",
    clarivateImpactFactor: "",
    rating: "",
    popupSlideTimer: "",
    journalOverview: "",
    advertisement: "",
    abstractingIndexing: "",
    supplementaryInfo: "",
    authorInstructions: "",
    policy: "",
    disclaimer: "",
  });
  const [tableRows, setTableRows] = useState([
    { id: 0, category: "", subject: [{ id: 0, subId: "" }] },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [societyTableRow, setSocietyTableRow] = useState([
    { id: 0, primary: false, societyTitle: "" },
  ]);
  const handlePrimaryChange = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  const files = useSelector((state) => state.file.files);

  const deleteRow = (index) => {
    if (index > 0) {
      const updatedRows = tableRows.filter((_, rowIndex) => rowIndex !== index);
      setTableRows(updatedRows);
    }
  };

  const deleteSocietyRow = (index) => {
    if (index > 0) {
      const updatedRows = societyTableRow.filter(
        (_, rowIndex) => rowIndex !== index
      );
      setSocietyTableRow(updatedRows);
    }
  };
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleTextChange = (name, updatedData) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: updatedData,
    }));
  };

  const handleCancel = () => {
    setFormValues({
      accessType: "",
      licenseType: "",
      journalCode: "",
      issn: "",
      onlineIssn: "",
      doi: "",
      journalTitle: "",
      journalSubtitle: "",
      journalShortName: "",
      journalURL: "",
      readerShip: "",
      lensURL: "",
      journalCoverImg: "",
      journalCoverImg1: "",
      journalCoverImg2: "",
      journalCoverImg3: "",
      twitterProfile: "",
      facebookProfile: "",
      instagramProfile: "",
      youtubeUrl: "",
      linkedIn: "",
      plumXCode: "",
      email: "",
      articleOfMonth: false,
      featured: false,
      downloadEnabled: false,
      accessible: true,
      availableOAI: false,
      availableRepec: false,
      numIssues: "",
      clarivateImpactFactor: "",
      rating: "",
      popupSlideTimer: "",
      journalOverview: "",
      advertisement: "",
      abstractingIndexing: "",
      supplementaryInfo: "",
      authorInstructions: "",
      policy: "",
      disclaimer: "",
    });
    navigate("/journals");
  };

  const ratingOpt = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];

  const fileInputKey = {
    journalCoverImg: 0,
    journalCoverImg1: 0,
    journalCoverImg2: 0,
    journalCoverImg3: 0,
  };

  const dispatch = useDispatch();
  const {
    subjectDetailsOptions,
    accessTypeOptions,
    societyDetailsOptions,
    loadingSubjectDetails,
    loadingAccessType,
    loadingSocietyDetails,
    licenseTypeOptions,
    loadingLicenseType,
    error,
  } = useSelector((state) => state.options);

  const transformedCategoryOptions = subjectDetailsOptions
    ?.filter((val) => val.subject_type === "Category")
    .map((item) => ({
      label: item.subject_name,
      value: item.id,
    }));

  const transformedSubjectOptions = subjectDetailsOptions
    ?.filter((val) => val.subject_type === "Specialty")
    .map((item) => ({
      label: item.subject_name,
      value: item.id,
    }));

  const transformedAccessTypeOptions = accessTypeOptions?.map((item) => ({
    label: item.access_type,
    value: item.id,
  }));

  const transformeSocietyOptions = societyDetailsOptions?.map((item) => ({
    label: item.society_title,
    value: item.id,
  }));

  const transformedLicenseTypeOptions = licenseTypeOptions?.map((item) => ({
    label: item.license_type,
    value: item.id,
  }));

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchCategoryOptions());
      await dispatch(fetchAccessTypeOptions());
      await dispatch(fetchSocietyOptions());
      await dispatch(fetchLicenseTypeOptions());
      await dispatch(clearFileErrors());

      if (id) {
        const response = await dispatch(
          fetchEntityData({
            entity: "JournalDetails",
            page: 0,
            size: 5,
            body: {
              filter: [
                {
                  id: {
                    equals: id,
                  },
                },
              ],
            },
          })
        );

        if (fetchEntityData.fulfilled.match(response)) {
          const patchFormValues = (data) => {
            const journalData = data?.data?.data?.content?.[0];
            setFormValues({
              accessType: journalData?.access_type_id?.id,
              licenseType: journalData?.license_type_id?.id,
              journalCode: journalData?.journal_code,
              issn: journalData?.print_issn_number,
              onlineIssn: journalData?.online_issn_number,
              doi: journalData?.doi,
              journalTitle: journalData?.journal_title,
              journalSubtitle: journalData?.journal_subtitle,
              journalShortName: journalData?.journal_short_name,
              journalURL: journalData?.journal_url,
              readerShip: journalData?.readership,
              lensURL: journalData?.lens_url,
              twitterProfile: journalData?.twitter_profile,
              facebookProfile: journalData?.facebook_profile,
              instagramProfile: journalData?.instagram_profile,
              youtubeUrl: journalData?.youtube_url,
              linkedIn: journalData?.linkedin_profile,
              plumXCode: journalData?.plumx_code,
              email: journalData?.email,
              numIssues: journalData?.no_of_issues,
              clarivateImpactFactor: journalData?.clarivate_impact_factor,
              rating: journalData?.rating,
              popupSlideTimer: journalData?.popup_slider_timer,
              journalOverview: journalData?.journal_overview,
              advertisement: journalData?.advertisement,
              abstractingIndexing: journalData?.abstract_and_index,
              supplementaryInfo: journalData?.supplementary_information,
              authorInstructions: journalData?.author_instructions,
              policy: journalData?.policy,
              disclaimer: journalData?.disclaimer,
              journal_cover_image_path: journalData?.journal_cover_image_path,
              journal_cover_image_path_accessUrl:
                journalData?.journal_cover_image_path_accessUrl,
              journal_cover_image_one_path:
                journalData?.journal_cover_image_one_path,
              journal_cover_image_one_path_accessUrl:
                journalData?.journal_cover_image_one_path_accessUrl,
              journal_cover_image_two_path:
                journalData?.journal_cover_image_two_path,
              journal_cover_image_two_path_accessUrl:
                journalData?.journal_cover_image_two_path_accessUrl,
              journal_cover_image_three_path:
                journalData?.journal_cover_image_three_path,
              journal_cover_image_three_path_accessUrl:
                journalData?.journal_cover_image_three_path_accessUrl,
            });

            if (journalData) {
              dispatch(
                setFileData({
                  fieldName: "journalCoverImg",
                  pathname: journalData.journal_cover_image_path,
                  accessurl: journalData.journal_cover_image_path_accessUrl,
                })
              );

              dispatch(
                setFileData({
                  fieldName: "journalCoverImg1",
                  pathname: journalData.journal_cover_image_one_path,
                  accessurl: journalData.journal_cover_image_one_path_accessUrl,
                })
              );

              dispatch(
                setFileData({
                  fieldName: "journalCoverImg2",
                  pathname: journalData.journal_cover_image_two_path,
                  accessurl: journalData.journal_cover_image_two_path_accessUrl,
                })
              );

              dispatch(
                setFileData({
                  fieldName: "journalCoverImg3",
                  pathname: journalData.journal_cover_image_three_path,
                  accessurl:
                    journalData.journal_cover_image_three_path_accessUrl,
                })
              );
            }
            const subjectRows = journalData?.subjects?.map((subject) => ({
              id: subject.id,
              category: subject.category_id?.id,
              subject: subject.subjects.map((s) => {
                return { id: s.id, subId: s.subject_details_id?.id };
              }),
            })) || [{ category: "", subject: [] }];
            setTableRows(subjectRows);

            const societyRows = journalData?.societies?.map((society) => ({
              id: society.id,
              primary: society.primary_flag,
              societyTitle: society.journal_society_id?.id,
            })) || [{ primary: false, societyTitle: "" }];
            setSocietyTableRow(societyRows);
          };
          patchFormValues(response.payload);
        } else {
          console.warn(
            "Failed to fetch entity data:",
            response.error?.message || "Unknown error"
          );
        }
      }
    };

    fetchData();
  }, [dispatch, id]);

  if (
    loadingSubjectDetails ||
    loadingAccessType ||
    loadingSocietyDetails ||
    loadingLicenseType
  ) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleValueChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleDropdownChange = (value, rowIndex, field) => {
    setTableRows((prevRows) => {
      const updatedRows = [...prevRows];
      if (field === "subject") {
        updatedRows[rowIndex].subject = value.map((id, index) => ({
          id: index,
          subId: id,
        }));
      } else {
        updatedRows[rowIndex][field] = value;
      }
      return updatedRows;
    });
  };

  const handleDropdownChangeSociety = (value, rowIndex, field) => {
    const newRows = [...societyTableRow];
    newRows[rowIndex] = { ...newRows[rowIndex], [field]: value };
    setSocietyTableRow(newRows);
  };

  const addRow = () => {
    setTableRows([
      ...tableRows,
      { category: "", subject: [{ id: 0, subId: "" }] },
    ]);
  };

  const addRowSociety = () => {
    setSocietyTableRow([
      ...societyTableRow,
      { primary: false, societyTitle: "" },
    ]);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      width: "30%",
      key: "category",
      render: (_, record, rowIndex) => {
        return (
          <div className="table-row-custom">
            <CustomDropdown
              options={transformedCategoryOptions}
              onChange={(value) =>
                handleDropdownChange(value, rowIndex, "category")
              }
              value={record.category}
              theme={theme}
            />
          </div>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (_, record, rowIndex) => (
        <div className="table-row-custom">
          <CustomDropdown
            value={record.subject.map((s) => s.subId)}
            options={transformedSubjectOptions}
            onChange={(selectedValues) =>
              handleDropdownChange(selectedValues, rowIndex, "subject")
            }
            theme={theme}
            multiple={true}
          />
        </div>
      ),
    },
    {
      //   title: "Action",
      key: "action",
      width: "20%",
      render: (_, __, rowIndex) =>
        rowIndex > 0 && (
          <div className="table-row-custom">
            <Button
              type="link"
              className="mt-3"
              icon={<DeleteOutline />}
              onClick={() => deleteRow(rowIndex)}
            />
          </div>
        ),
    },
  ];

  const societyColumns = [
    {
      title: "Primary",
      width: "30%",
      dataIndex: "primary",
      key: "primary",
      render: (_, __, rowIndex) => (
        <div className="mb-4">
          <Radio
            checked={selectedRow === rowIndex}
            onChange={() => handlePrimaryChange(rowIndex)}
          />
        </div>
      ),
    },
    {
      title: "Society Title",
      dataIndex: "societyTitle",
      key: "societyTitle",
      render: (_, record, rowIndex) => (
        <div className="table-row-custom">
          <CustomDropdown
            value={record.societyTitle}
            options={transformeSocietyOptions}
            onChange={(value) =>
              handleDropdownChangeSociety(value, rowIndex, "societyTitle")
            }
            theme={theme}
            removable={true}
          />
        </div>
      ),
    },
    {
      //   title: "Action",
      key: "action",
      width: "20%",
      render: (_, __, rowIndex) =>
        rowIndex > 0 && (
          <div className="table-row-custom">
            <Button
              type="link"
              className="mt-3"
              icon={<DeleteOutline />}
              onClick={() => deleteSocietyRow(rowIndex)}
            />
          </div>
        ),
    },
  ];

  const fetchFileUrl = (fieldName) => {
    let file = {};
    switch (fieldName) {
      case "journalCoverImg":
        file = {
          fileName: formValues.journal_cover_image_path,
          fileUrl: formValues.journal_cover_image_path_accessUrl,
        };
        break;
      case "journalCoverImg1":
        file = {
          fileName: formValues.journal_cover_image_one_path,
          fileUrl: formValues.journal_cover_image_one_path_accessUrl,
        };
        break;
      case "journalCoverImg2":
        file = {
          fileName: formValues.journal_cover_image_two_path,
          fileUrl: formValues.journal_cover_image_two_path_accessUrl,
        };
        break;
      case "journalCoverImg3":
        file = {
          fileName: formValues.journal_cover_image_three_path,
          fileUrl: formValues.journal_cover_image_three_path_accessUrl,
        };
        break;
      default:
        file = {
          fileName: formValues.journal_cover_image_path,
          fileUrl: formValues.journal_cover_image_path_accessUrl,
        };
    }
    return file;
  };

  const saveJournal = () => {
    if (
      formValues.accessType === "" ||
      formValues.journalCode === "" ||
      formValues.journalTitle === "" ||
      formValues.journalURL === ""
    ) {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
    let subjectArr = [];
    tableRows.forEach((element) => {
      let subArrOfSubjects = [];

      element?.subject?.forEach((item) => {
        subArrOfSubjects.push({
          id: id ? (item?.id ?? 0) : 0,
          subject_details_id: {
            id: item?.subId,
          },
        });
      });

      subjectArr.push({
        id: id ? (element.id ?? 0) : 0,
        category_id: { id: element?.category },
        subjects: subArrOfSubjects,
      });
    });
    let societyArr = [];
    societyTableRow.forEach((element) => {
      societyArr.push({
        id: id ? (element.id ?? 0) : 0,
        primary_flag: element.primary ?? false,
        journal_society_id: {
          id: element.societyTitle,
        },
      });
    });
    const requestBody = {
      id: id ?? 0,
      access_type_id: {
        id: formValues.accessType,
      },
      license_type_id: {
        id: formValues.licenseType,
      },
      subjects: subjectArr,
      societies: societyArr,
      journal_code: formValues.journalCode,
      print_issn_number: formValues.issn,
      online_issn_number: formValues.onlineIssn,
      doi: formValues.doi,
      journal_title: formValues.journalTitle,
      journal_subtitle: formValues.journalSubtitle,
      journal_short_name: formValues.journalShortName,
      journal_url: formValues.journalURL,
      lens_url: formValues.lensURL,
      readership: formValues.readerShip,

      journal_cover_image_path_base64: files.journalCoverImg?.fileBase64 || "",
      journal_cover_image_path_file_name: files.journalCoverImg?.fileName || "",
      journal_cover_image_one_path_base64:
        files.journalCoverImg1?.fileBase64 || "",
      journal_cover_image_one_path_file_name:
        files.journalCoverImg1?.fileName || "",
      journal_cover_image_two_path_base64:
        files.journalCoverImg2?.fileBase64 || "",
      journal_cover_image_two_path_file_name:
        files.journalCoverImg2?.fileName || "",
      journal_cover_image_three_path_base64:
        files.journalCoverImg3?.fileBase64 || "",
      journal_cover_image_three_path_file_name:
        files.journalCoverImg3?.fileName || "",
      twitter_profile: formValues.twitterProfile,
      facebook_profile: formValues.facebookProfile,
      instagram_profile: formValues.instagramProfile,
      linkedin_profile: formValues.linkedin_profile,
      youtube_url: formValues.youtubeUrl,
      plumx_code: formValues.plumXCode,
      email: formValues.email,
      no_of_issues: formValues.numIssues,
      clarivate_impact_factor: formValues.clarivateImpactFactor,
      rating: formValues.rating,
      popup_slider_timer: formValues.popupSlideTimer,
      journal_overview: formValues.journalOverview,
      advertisement: formValues.advertisement,
      abstract_and_index: formValues.abstractingIndexing,
      supplementary_information: formValues.supplementaryInfo,
      author_instructions: formValues.authorInstructions,
      policy: formValues.policy,
      disclaimer: formValues.disclaimer,
      active_status: true,
    };
    console.log(requestBody, "REQ BODY");
    mutate(
      {
        url: ENDPOINTS.saveOrUpdateJournal,
        data: requestBody,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage(
            `Journal ${id ? "updated " : "saved "} successfully!`
          );
          onSaveJournal();
        },
        onError: (err) => {
          console.log("Error!", err);
          showErrorMessage("Dupicate Entry! Please add unique data.");
        },
      }
    );
  };

  return (
    <>
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3" style={cardStyle}>
        <Form layout="vertical" style={{ padding: "20px" }}>
          <div className="row">
            <div className="col-md-6">
              <CustomDropdown
                label="Access Type"
                name="accessType"
                value={formValues.accessType}
                onChange={handleValueChange}
                options={transformedAccessTypeOptions}
                theme={theme}
                required
              />
            </div>
            <div className="col-md-6">
              <CustomDropdown
                label="License Type"
                name="licenseType"
                value={formValues.licenseType}
                onChange={handleValueChange}
                options={transformedLicenseTypeOptions}
                theme={theme}
                required
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <Form.Item
                label={
                  <span style={{ color: theme === "dark" ? "white" : "black" }}>
                    Subject
                  </span>
                }
                required={true}
                labelCol={{ span: 24 }}
                style={{ marginBottom: "8px" }}
              >
                <Table
                  columns={columns}
                  dataSource={tableRows}
                  pagination={false}
                  rowKey={(record, index) => record.id || index}
                  style={{
                    ...tableStyle,
                    marginTop: "0px",
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }}
                />
                <Button
                  onClick={addRow}
                  type="dashed"
                  style={{
                    marginTop: "10px",
                    float: "right",
                    height: "30px",
                    width: "100px",
                    ...buttonStyle,
                  }}
                >
                  + Add More
                </Button>
              </Form.Item>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <Form.Item
                label={
                  <span style={{ color: theme === "dark" ? "white" : "black" }}>
                    Society
                  </span>
                }
                required={true}
                labelCol={{ span: 24 }}
                style={{ marginBottom: "8px" }}
              >
                <Table
                  columns={societyColumns}
                  dataSource={societyTableRow}
                  pagination={false}
                  rowKey={(record) =>
                    `${record.societyTitle}-${record.primary}-${Math.random()}`
                  }
                  style={{
                    ...tableStyle,
                    marginTop: "0px",
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }}
                />
                <Button
                  onClick={addRowSociety}
                  type="dashed"
                  style={{
                    marginTop: "10px",
                    float: "right",
                    height: "30px",
                    width: "100px",
                    ...buttonStyle,
                  }}
                >
                  + Add More
                </Button>
              </Form.Item>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <CustomInput
                label="Journal Code"
                type="text"
                required
                name="journalCode"
                value={formValues.journalCode}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Print ISSN No"
                type="number"
                name="issn"
                value={formValues.issn}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Online ISSN No"
                type="number"
                name="onlineIssn"
                value={formValues.onlineIssn}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="DOI"
                type="text"
                name="doi"
                value={formValues.doi}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Journal Title"
                type="text"
                required
                name="journalTitle"
                value={formValues.journalTitle}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Journal Subtitle"
                type="text"
                name="journalSubtitle"
                value={formValues.journalSubtitle}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Journal Short Name"
                type="text"
                name="journalShortName"
                value={formValues.journalShortName}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Journal URL"
                required
                type="text"
                name="journalURL"
                value={formValues.journalURL}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="ReaderShip"
                type="text"
                name="readerShip"
                value={formValues.readerShip}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Lens URL"
                type="text"
                name="lensURL"
                value={formValues.lensURL}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              {files.journalCoverImg?.fileError && (
                <div style={{ color: "red" }}>
                  {files.journalCoverImg?.fileError}
                </div>
              )}
              <CustomInput
                key={fileInputKey.journalCoverImg}
                label={
                  <span className="flex">
                    Journal Cover Image{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg; Recommend pixel size: [320,450]">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                required
                type="file"
                name="journalCoverImg"
                value={formValues.journalCoverImg}
                onChange={(e) =>
                  handleFileChange(
                    e,
                    ["image/jpg", "image/jpeg"],
                    1,
                    {
                      width: 320,
                      height: 450,
                    },
                    "journalCoverImg"
                  )
                }
                theme={theme}
                fileUrl={() => fetchFileUrl("journalCoverImg")}
              />
            </div>
            <div className="col-md-6">
              {files.journalCoverImg1?.fileError && (
                <div style={{ color: "red" }}>
                  {files.journalCoverImg1?.fileError}
                </div>
              )}
              <CustomInput
                key={fileInputKey.journalCoverImg1}
                label={
                  <span className="flex">
                    Journal Cover Image 1{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg or .png; Recommend pixel size: [320,450]">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                type="file"
                name="journalCoverImg1"
                value={formValues.journalCoverImg1}
                onChange={handleChange}
                theme={theme}
                fileUrl={() => fetchFileUrl("journalCoverImg1")}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                key={fileInputKey.journalCoverImg2}
                label={
                  <span className="flex">
                    Journal Cover Image 2{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg or .png; Recommend pixel size: [320,450]">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                type="file"
                name="journalCoverImg2"
                value={formValues.journalCoverImg2}
                onChange={handleChange}
                theme={theme}
                fileUrl={() => fetchFileUrl("journalCoverImg2")}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                key={fileInputKey.journalCoverImg3}
                label={
                  <span className="flex">
                    Journal Cover Image 3{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .jpeg or .png; Recommend pixel size: [320,450]">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                type="file"
                name="journalCoverImg3"
                value={formValues.journalCoverImg3}
                onChange={handleChange}
                theme={theme}
                fileUrl={() => fetchFileUrl("journalCoverImg3")}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Twitter Profile"
                type="text"
                name="twitterProfile"
                value={formValues.twitterProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Facebook Profile"
                type="text"
                name="facebookProfile"
                value={formValues.facebookProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Instagram Profile"
                type="text"
                name="instagramProfile"
                value={formValues.instagramProfile}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Youtube URL"
                type="text"
                name="youtubeUrl"
                value={formValues.youtubeUrl}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Linkedin"
                type="text"
                name="linkedIn"
                value={formValues.linkedIn}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="PlumX Code"
                type="text"
                name="plumXCode"
                value={formValues.plumXCode}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="Email"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-3 mt-4">
              <CustomInput
                label="Article of the month"
                type="checkbox"
                name="articleOfMonth"
                value={formValues.articleOfMonth}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-3 mt-4">
              <CustomInput
                label="Featured"
                type="checkbox"
                name="featured"
                value={formValues.featured}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3 mt-2">
              <CustomInput
                label="Download Enable"
                type="checkbox"
                name="downloadEnabled"
                value={formValues.downloadEnabled}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-3 mt-2">
              <CustomInput
                label="Accessible"
                type="checkbox"
                name="accessible"
                value={formValues.accessible}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-3 mt-2">
              <CustomInput
                label="Available in OAI"
                type="checkbox"
                name="availableOAI"
                value={formValues.availableOAI}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-3 mt-2">
              <CustomInput
                label="Available in RePec"
                type="checkbox"
                name="availableRepec"
                value={formValues.availableRepec}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomInput
                label="No Of Issues"
                type="text"
                name="numIssues"
                value={formValues.numIssues}
                onChange={handleChange}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Clarivate's Impact Factor"
                type="text"
                name="clarivateImpactFactor"
                value={formValues.clarivateImpactFactor}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <CustomDropdown
                label="Rating"
                name="rating"
                value={formValues.rating}
                onChange={handleValueChange}
                options={ratingOpt}
                theme={theme}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                label="Popup Slide Timer"
                type="text"
                name="popupSlideTimer"
                value={formValues.popupSlideTimer}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Journal Overview"
                name="journalOverview"
                value={formValues.journalOverview}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Advertisement"
                name="advertisement"
                value={formValues.advertisement}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Abstracting & Indexing"
                name="abstractingIndexing"
                value={formValues.abstractingIndexing}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Supplementary Information"
                name="supplementaryInfo"
                value={formValues.supplementaryInfo}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Author Instructions"
                name="authorInstructions"
                value={formValues.authorInstructions}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Policy"
                name="policy"
                value={formValues.policy}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Disclaimer"
                name="disclaimer"
                value={formValues.disclaimer}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveJournal}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddJournal;
