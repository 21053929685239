import React from 'react';
import { CardTitle } from 'react-bootstrap';
import { Typography } from 'antd';

const PageTitle = ({ title, color, className = "mt-3" }) => {
  return (
    <CardTitle
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Typography.Title level={4} style={{ color, margin: 0 }}>
        {title}
      </Typography.Title>
    </CardTitle>
  );
};

export default PageTitle;
