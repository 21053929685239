import React from "react";
import { Form, Select } from "antd";

const CustomDropdown = ({
  label,
  name,
  value,
  onChange,
  options,
  placeholder,
  theme,
  required = false,
  multiple = false,
  removable = false,
}) => {
  const darkThemeStyles = {
    color: "white",
    backgroundColor: "#333",
    dropdownTextColor: "white",
    dropdownBackgroundColor: "#333",
  };

  const lightThemeStyles = {
    color: "black",
    backgroundColor: "white",
    dropdownTextColor: "black",
    dropdownBackgroundColor: "white",
  };

  const currentThemeStyles =
    theme === "dark" ? darkThemeStyles : lightThemeStyles;

  const handleChange = (selectedValue) => {
    if (!name) {
      onChange(selectedValue);
    } else {
      onChange(name, selectedValue);
    }
  };
  return (
    <Form.Item
      label={<span style={{ color: currentThemeStyles.color }}>{label}</span>}
      required={required}
      labelCol={{ span: 24 }}
      style={{ marginBottom: "16px" }}
    >
      <Select
        mode={multiple ? "multiple" : undefined}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        showSearch
        allowClear={removable}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        style={{
          ...currentThemeStyles,
          width: "100%",
        }}
        dropdownStyle={{
          backgroundColor: currentThemeStyles.dropdownBackgroundColor,
          color: currentThemeStyles.dropdownTextColor,
        }}
        options={options.map((option) => ({
          label: option.label,
          value: option.value,
        }))}
      />
    </Form.Item>
  );
};

export default CustomDropdown;
