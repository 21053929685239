import React, { useRef, useState, useContext } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import store from "./store/store";
import "./App.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import AppRouter from "./routes/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "./components/ThemeContext";
import ThemeContext from "./components/ThemeContext";
import { QueryClient, QueryClientProvider } from "react-query";
import Footer from "./components/Footer";

const App = () => {
  const [open, setOpen] = useState(true);

  // const mainContainerRef = useRef(null);
  const handleSidebarToggle = () => {
    setOpen(!open);
  };

  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          <QueryClientProvider client={queryClient}>
            <MainApp
              handleSidebarToggle={handleSidebarToggle}
              sidebarOpen={open}
            />
          </QueryClientProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

const MainApp = ({ handleSidebarToggle, sidebarOpen }) => {
  const mainContainerRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const location = useLocation();

  return (
    <div
      className="flex flex-col h-screen"
      style={{ backgroundColor: theme === "dark" ? "#000000" : "#faf9f9" }}
    >
      <Header onSidebarToggle={handleSidebarToggle} sidebarOpen={sidebarOpen} />
      <div className="flex flex-grow overflow-hidden">
        {location.pathname !== "/home" && <Navbar sidebarOpen={sidebarOpen} />}
        <div
          className={`flex-grow text-gray-900 ${
            sidebarOpen ? "w-[calc(100%-14rem)]" : "w-[calc(100%-5rem)]"
          }`}
        >
          <main
            ref={mainContainerRef}
            className="p-8 w-100 overflow-auto h-[calc(100vh-54px)]"
          >
            <AppRouter />
          </main>
        </div>
      </div>
      {/* <ScrollToTop containerRef={mainContainerRef} /> */}
      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      {location.pathname !== '/home' && (<Footer/>)}
    </div>
  );
};

export default App;
