import React, { useContext } from "react";
import ThemeContext from "./ThemeContext";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const currentYear =  new Date().getFullYear();
  return (
    <footer
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        backgroundColor: theme === "dark" ? "#000000" : "#faf9f9",
        color: theme === "dark" ? "white" : "black",
      }}
    >
      {location.pathname === "/home" ? (
        <span>©{currentYear} Global Newborn Society, <em>"Every Baby Counts"</em></span>
      ) : (<span>{currentYear} © MPS LTD </span>)}
    </footer>
  );
};

export default Footer;
