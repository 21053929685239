import React from "react";
import { Card, Typography } from "antd";

const { Meta } = Card;
const { Text, Paragraph } = Typography;

const ArticleCard = ({ title, author, description, doi, imageUrl, theme }) => {
  const cardStyle = {
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#fff",
    color: theme === "dark" ? "white" : "#001529",
    borderRadius: "10px",
    boxShadow:
      theme === "dark"
        ? "0px 0px 10px rgba(255, 255, 255, 0.2)"
        : "0px 0px 10px rgba(0, 0, 0, 0.1)",
    width: 340,
    margin: "16px",
    overflow: "hidden",
  };

  return (
    <Card
      hoverable
      style={cardStyle}
      cover={<img alt={title} src={imageUrl} style={{ height: 180, objectFit: "cover" }} />}
    >
      <Meta
        title={<Text style={{ fontSize: "18px", fontWeight: "bold", color: cardStyle.color }}>{title}</Text>}
        description={
          <>
            <Text style={{ fontSize: "14px", color: theme === "dark" ? "#aaa" : "#888" }}>{author}</Text>
            <Paragraph style={{ fontSize: "16px", margin: "8px 0", color: cardStyle.color }}>{description}</Paragraph>
            <Text style={{ fontSize: "12px", color: theme === "dark" ? "#00bcd4" : "#007acc" }}>{doi}</Text>
          </>
        }
      />
    </Card>
  );
};

export default ArticleCard;
