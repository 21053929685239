import React, { useContext, useEffect, useState } from "react";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import PageTitle from "../../components/shared/PageTitle";
import themeStyles from "../../components/shared/ThemeStyles";
import ThemeContext from "../../components/ThemeContext";
import { Menu } from "antd";
import AddJournal from "./AddJournal";
import SupplementaryFiles from "./SupplementaryFiles";
import EditorRole from "./EditorRole";
import Editor from "./Editor";
import JournalPopup from "./JournalPopup";
import JournalBanner from "./JournalBanner";
import { useLocation, useNavigate } from "react-router-dom";

const JournalMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { label: "Journals", onClick: () => navigate("/journals") },
    { label: "Create Journal" },
  ]);
  const { theme } = useContext(ThemeContext);
  const { cardStyle } = themeStyles(theme);
  const [pageTitle, setPageTitle] = useState("Create Journal");
  const [activeMenu, setActiveMenu] = useState("createJournal");
  const [isJournalSaved, setIsJournalSaved] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const editMode = /\/journals\/edit-journal\/\d+$/.test(location.pathname);
    setIsEditMode(editMode);
    if (editMode) {
      setIsJournalSaved(true);
      setBreadcrumbItems([
        { label: "Journals", onClick: () => navigate("/journals") },
        { label: "Edit Journal" },
      ]);
      setPageTitle("Edit Journal");
    }
  }, [location.pathname, navigate]);

  const handleSaveJournal = () => {
    setIsJournalSaved(true);
    setActiveMenu("supplementaryFiles");
    onClick({ key: "supplementaryFiles" });
  };

  const onClick = (e) => {
    const selectedItem = items.find((item) => item.key === e.key);
    if (selectedItem) {
      setActiveMenu(e.key);
      setBreadcrumbItems([
        { label: "Journals", onClick: () => navigate("/journals") },
        { label: selectedItem.label },
      ]);
      setPageTitle(selectedItem.label);
    }
  };

  const items = [
    {
      label: isEditMode ? "Edit Journal" : "Create Journal",
      key: "createJournal",
    },
    {
      label: "Supplementary Files",
      key: "supplementaryFiles",
    },
    {
      label: "Editor Role",
      key: "editorRole",
    },
    {
      label: "Editor",
      key: "editor",
    },
    {
      label: "Journal Popup",
      key: "journalPopup",
    },
    {
      label: "Journal Banner",
      key: "journalBanner",
    },
  ];
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title={pageTitle} color={cardStyle.color} />
      <div className="mt-3" style={{ display: "flex" }}>
        <Menu
          theme={theme}
          mode="horizontal"
          selectedKeys={[activeMenu]}
          style={{
            width: "100%",
            backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "white",
          }}
        >
          <Menu.Item key="createJournal" onClick={onClick}>
            {isEditMode ? "Edit Journal" : "Create Journal"}
          </Menu.Item>
          <Menu.Item
            key="supplementaryFiles"
            onClick={onClick}
            disabled={!isJournalSaved}
          >
            Supplementary Files
          </Menu.Item>
          <Menu.Item
            key="editorRole"
            onClick={onClick}
            disabled={!isJournalSaved}
          >
            Editor Role
          </Menu.Item>
          <Menu.Item key="editor" onClick={onClick} disabled={!isJournalSaved}>
            Editor
          </Menu.Item>
          <Menu.Item
            key="journalPopup"
            onClick={onClick}
            disabled={!isJournalSaved}
          >
            Journal Popup
          </Menu.Item>
          <Menu.Item
            key="journalBanner"
            onClick={onClick}
            disabled={!isJournalSaved}
          >
            Journal Banner
          </Menu.Item>
        </Menu>
      </div>
      <div style={{ marginLeft: 20, flexGrow: 1 }}>
        {activeMenu === "createJournal" && (
          <AddJournal onSaveJournal={handleSaveJournal} />
        )}
        {activeMenu === "supplementaryFiles" && <SupplementaryFiles />}
        {activeMenu === "editorRole" && <EditorRole />}
        {activeMenu === "editor" && <Editor />}
        {activeMenu === "journalPopup" && <JournalPopup />}
        {activeMenu === "journalBanner" && <JournalBanner />}
      </div>
    </>
  );
};

export default JournalMenu;
