import React, { useContext, useEffect, useState } from "react";
import themeStyles from "../../components/shared/ThemeStyles";
import useCustomColumnSearch from "../../components/shared/CustomColumnSearch";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, Form, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ThemeContext from "../../components/ThemeContext";
import Loader from "../../components/shared/Loader";
import CustomInput from "../../components/shared/CustomInput";
import Table from "../../components/Table";
import { useFileHandlers, usePostData } from "../../hooks/useData";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ENDPOINTS } from "../../services/endpoints";
import { useParams } from "react-router-dom";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../components/shared/MessageUtils";
import { fetchEntityData, setEntityDataPageIndex, setEntityDataPageSize } from "../../store/slices/getListSlice";

const SupplementaryFiles = () => {
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const { getColumnSearchProps } = useCustomColumnSearch();
  const handleEdit = (item) => {};
  const { id } = useParams();
  const dispatch = useDispatch();
  const { mutate } = usePostData();
  const { loading, limit, pageIndex, data, totalItems } = useSelector(
    (state) => state.entityData
  );
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [formValues, setFormValues] = useState({
    seqNo: "",
    title: "",
    uploadFile: "",
    uploadFilePath: "",
    uploadFileAccessUrl: "",
  });
  const [isValidForm, setIsValidForm] = useState(true);
  const files = useSelector((state) => state.file.files);
  const { handleFileChange } = useFileHandlers();
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleCancel = () => {
    setFormValues({
      seqNo: "",
      title: "",
      uploadFile: "",
      uploadFilePath: "",
      uploadFileAccessUrl: "",
    });
    setIsUploadingFile(false);
  };
  const allowedFormats = [
    "application/msword", // .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.ms-excel", // .xls
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.ms-powerpoint", // .ppt
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/pdf", // .pdf
    "audio/wav", // .wav
    "audio/mpeg", // .mp3
    "video/mp4", // .mp4
    "text/plain", // .txt
    "image/jpeg", // .jpg, .jpeg
    "image/png", // .png
    "image/gif", // .gif
  ];
  const columns = [
    {
      title: "Seq. No.",
      dataIndex: "sequence_number",
      key: "sequence_number",
      ...getColumnSearchProps("sequence_number"),
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "journal_code",
      key: "journal_code",
      ...getColumnSearchProps("journal_code"),
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-sm btn-primary-outline"
          style={{ color: theme === "dark" ? "white" : "black" }}
          onClick={() => handleEdit(record)}
        >
          Edit
        </button>
      ),
    },
  ];

  const handleUpload = () => {
    setIsUploadingFile(true);
  };
  const handleTableChange = ({ pageIndex, pageSize, sortBy }) => {};

  const saveUploadFile = () => {
    if (
      formValues.seqNo === "" ||
      formValues.seqNo === 0 ||
      formValues.title === "" ||
      files.uploadFile?.fileBase64 === "" ||
      files.uploadFile?.fileName === ""
    ) {
      setIsValidForm(false);
    } else {
      setIsValidForm(true);
    }
    let body = {
      id: 0,
      journal_id: {
        id: Number(id),
      },
      sequence_number: Number(formValues.seqNo),
      file_path_base64: files.uploadFile.fileBase64,
      file_path_file_name: files.uploadFile.fileName,
      journal_code: formValues.title,
    };
    // console.log(body, "body");

    mutate(
      {
        url: ENDPOINTS.saveOrUpdateSupplementaryFiles,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage("Supplementary file uploaded successfully");
          setTimeout(() => {
            setIsUploadingFile(false);
          }, 1000);
        },
        onError: (err) => {
          const errorMessage = err?.message || "An unknown error occurred";
          console.log("Error:", errorMessage);
          showErrorMessage(errorMessage);
        },
      }
    );
  };

  useEffect(() => {
    dispatch(setEntityDataPageIndex(0));
    dispatch(setEntityDataPageSize(5));
    dispatch(
      fetchEntityData({
        entity: "JournalSupplementaryFiles",
        page: pageIndex,
        size: limit,
      })
    );
  }, [dispatch, pageIndex, limit]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {" "}
      {!isUploadingFile ? (
        <Card className="mt-3" style={cardStyle}>
          <Button
            type="primary"
            className="mb-4"
            style={{ float: "right" }}
            onClick={handleUpload}
            icon={<PlusOutlined />}
          >
            Upload
          </Button>
          <Table
            columns={columns}
            data={data}
            manualPagination={false}
            initialPageIndex={pageIndex}
            initialPageSize={limit}
            totalItems={totalItems}
            onTableChange={handleTableChange}
          />
        </Card>
      ) : (
        <>
          {!isValidForm && (
            <Alert
              className="mt-3"
              message="Please fill mandatory(*) fields."
              type="error"
              showIcon
              closable
            />
          )}
          <Card className="mt-3" style={cardStyle}>
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              layout="vertical"
              style={{ padding: "20px" }}
            >
              <div className="row">
                <div className="col-md-6">
                  <CustomInput
                    label="Seq No"
                    type="text"
                    required
                    name="seqNo"
                    value={formValues.seqNo}
                    onChange={handleChange}
                    theme={theme}
                  />
                </div>
                <div className="col-md-6">
                  <CustomInput
                    label="Title"
                    type="text"
                    required
                    name="title"
                    value={formValues.title}
                    onChange={handleChange}
                    theme={theme}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  {files.uploadFile?.fileError && (
                    <div style={{ color: "red" }}>
                      {files.uploadFile?.fileError}
                    </div>
                  )}
                  <CustomInput
                    label={
                      <span className="flex">
                        Upload File{" "}
                        <span className="ms-2">
                          <Tooltip
                            title={
                              <>
                                Allowed formats: .doc, .docx, .xls, .xlsx, .ppt,
                                .pptx, .pdf, .wav, .mp3, .mp4, .txt, .jpg,
                                .jpeg, .png, .gif;
                                <br />
                                Max file size: 60 MB
                              </>
                            }
                          >
                            <InformationCircleIcon
                              style={{
                                cursor: "pointer",
                                color: theme === "dark" ? "white" : "black",
                                width: "20px",
                              }}
                            />
                          </Tooltip>
                        </span>
                      </span>
                    }
                    required
                    type="file"
                    name="uploadFile"
                    value={formValues.uploadFile}
                    onChange={(e) =>
                      handleFileChange(
                        e,
                        allowedFormats,
                        1,
                        { width: 800, height: 600 },
                        "uploadFile"
                      )
                    }
                    theme={theme}
                  />
                </div>
              </div>
              <div className="text-right">
                <Button
                  style={buttonStyle}
                  type="submit"
                  onClick={saveUploadFile}
                  className="me-2"
                >
                  Save
                </Button>
                <Button
                  style={{
                    ...buttonStyle,
                    backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                    color: theme === "dark" ? "white" : "black",
                  }}
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Card>
        </>
      )}
    </>
  );
};

export default SupplementaryFiles;
