import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import useCustomColumnSearch from "../../components/shared/CustomColumnSearch";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usePostData } from "../../hooks/useData";
import {
  fetchEntityData,
  setEntityDataPageIndex,
  setEntityDataPageSize,
  setEntityDataSorting,
} from "../../store/slices/getListSlice";
import Loader from "../../components/shared/Loader";
import { Alert, Button, Card, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Table from "../../components/Table";
import CustomInput from "../../components/shared/CustomInput";
import CustomDropdown from "../../components/shared/CustomDropdown";
import { ENDPOINTS } from "../../services/endpoints";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../components/shared/MessageUtils";

const EditorRole = () => {
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const { getColumnSearchProps } = useCustomColumnSearch();
  const handleEdit = (item) => {};
  const { id } = useParams();
  const dispatch = useDispatch();
  const { mutate } = usePostData();
  const { loading, limit, pageIndex, data, totalItems } = useSelector(
    (state) => state.entityData
  );
  const [formValues, setFormValues] = useState({
    seqNo: "",
    editorRole: "",
  });
  const [isValidForm, setIsValidForm] = useState(true);
  const [isAddingData, setIsAddingData] = useState(false);
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleCancel = () => {
    setFormValues({
      seqNo: "",
      editorRole: "",
    });
    setIsAddingData(false);
  };

  const handleValueChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleAdd = () => {
    setIsAddingData(true);
  };

  const columns = [
    {
      title: "Seq. No.",
      dataIndex: "sequence_number",
      key: "sequence_number",
      ...getColumnSearchProps("sequence_number"),
      sorter: true,
    },
    {
      title: "Editor Role",
      key: "editor_role",
      ...getColumnSearchProps("editor_role"),
      sorter: true,
      render: (text, record) => record.editor_role_id?.editor_role || "-",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="btn btn-sm btn-primary-outline"
          style={{ color: theme === "dark" ? "white" : "black" }}
          onClick={() => handleEdit(record)}
        >
          Edit
        </button>
      ),
    },
  ];

  const [editorRoleOpts, setEditRoleOpts] = useState([]);

  const handleTableChange = ({ pageIndex, pageSize, sortBy }) => {
    dispatch(setEntityDataPageIndex(pageIndex));
    dispatch(setEntityDataPageSize(pageSize));
    dispatch(setEntityDataSorting(sortBy));
  };

  const saveEditorRole = () => {
    if (formValues.seqNo === "" || formValues.editorRole === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
    const body = {
      id: 0,
      journal_id: {
        id: id,
      },
      editor_role_id: {
        id: formValues.editorRole,
      },
      sequence_number: formValues.seqNo,
      active_status: true,
    };
    mutate(
      {
        url: ENDPOINTS.saveOrUpdateJournalEditorRoles,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage("Editor Role saved successfully!");
          setTimeout(() => {
            setIsAddingData(false);
          }, 1000);
        },
        onError: (err) => {
          const errorMessage = err?.message || "An unknown error occurred";
          console.log("Error:", errorMessage);
          showErrorMessage(errorMessage);
        },
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isAddingData) {
        const roleOpts = await dispatch(
          fetchEntityData({
            entity: "EditorRoles",
            page: 0,
            size: 1000,
          })
        );
        const options = roleOpts?.payload?.data?.data?.content?.map((item) => {
          return {
            ...item,
            value: item?.id,
            label: item?.editor_role,
          };
        });
        setEditRoleOpts(options);
      } else {
        dispatch(
          fetchEntityData({
            entity: "JournalEditorRoles",
            page: pageIndex,
            sixe: limit,
          })
        );
      }
    };
    fetchData();
  }, [dispatch, pageIndex, limit, isAddingData]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!isAddingData ? (
        <Card className="mt-3" style={cardStyle}>
          <Button
            type="primary"
            className="mb-4"
            style={{ float: "right" }}
            onClick={handleAdd}
            icon={<PlusOutlined />}
          >
            Add
          </Button>
          <Table
            columns={columns}
            data={data}
            manualPagination={false}
            initialPageIndex={pageIndex}
            initialPageSize={limit}
            totalItems={totalItems}
            onTableChange={handleTableChange}
          />
        </Card>
      ) : (
        <>
          {" "}
          {!isValidForm && (
            <Alert
              className="mt-3"
              message="Please fill mandatory(*) fields."
              type="error"
              showIcon
              closable
            />
          )}
          <Card className="mt-3" style={cardStyle}>
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 24 }}
              layout="vertical"
              style={{ padding: "20px" }}
            >
              <div className="row">
                <div className="col-md-6">
                  <CustomInput
                    label="Seq No"
                    type="text"
                    required
                    name="seqNo"
                    value={formValues.seqNo}
                    onChange={handleChange}
                    theme={theme}
                  />
                </div>
                <div className="col-md-6">
                  <CustomDropdown
                    label="Editor Role"
                    name="editorRole"
                    value={formValues.editorRole}
                    onChange={handleValueChange}
                    options={editorRoleOpts}
                    theme={theme}
                    required
                  />
                </div>
              </div>
              <div className="mt-2 text-right">
                <Button
                  style={buttonStyle}
                  type="submit"
                  onClick={saveEditorRole}
                  className="me-2"
                >
                  Save
                </Button>
                <Button
                  style={{
                    ...buttonStyle,
                    backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                    color: theme === "dark" ? "white" : "black",
                  }}
                  type="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Card>
        </>
      )}
    </>
  );
};

export default EditorRole;
