import React, { useContext, useState } from "react";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { Alert, Form, Button, Card } from "antd";
import CustomInput from "../../components/shared/CustomInput";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/shared/PageTitle";

const AddArticleType = () => {
  const breadcrumbItems = [
    { label: "Article Type", onClick: () => navigate("/articleType") },
    { label: "Add Article Type" },
  ];
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const [formValues, setFormValues] = useState({ articleType: "" });
  const [isValidForm, setIsValidForm] = useState(true);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleCancel = () => {
    setFormValues({
      articleType: "",
    });
    navigate("/articleType");
  };
  const saveArticleType = () => {
    if (formValues.articleType === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }
  };
  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Add Article Type" color={cardStyle.color} />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-6">
              <CustomInput
                label="Article Type"
                type="text"
                required
                name="articleType"
                value={formValues.articleType}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveArticleType}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddArticleType;
