import React from 'react';

const customizeRequiredMark = (label, { required }) => (
  <>
    {label}
    {required && <span style={{ color: 'red' }}>*</span>}
  </>
);

export default customizeRequiredMark;
