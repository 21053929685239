import React, { useEffect, useState } from "react";
import { Checkbox, Form, Input } from "antd";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useFileHandlers } from "../../hooks/useData";

const CustomInput = ({
  label,
  type,
  required,
  name,
  value,
  onChange,
  placeholder,
  theme,
  fileUrl,
}) => {
  const darkThemeStyles = {
    color: "white",
    backgroundColor: "#333",
  };

  const lightThemeStyles = {
    color: "black",
    backgroundColor: "white",
  };

  const currentThemeStyles =
    theme === "dark" ? darkThemeStyles : lightThemeStyles;

  const [file, setFile] = useState(null);
  const { resetFileInput } = useFileHandlers();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (onChange) onChange(e);
  };

  const handleFileDelete = () => {
    setFile(null);
    if (onChange) onChange({ target: { name, value: null } });
    resetFileInput(name);
  };

  useEffect(() => {
    if (fileUrl) {
      const fileData = fileUrl();
      if (fileData && fileData.fileUrl) {
        const fileName = fileData.fileName.split("/").pop();
        setFile({ name: fileName, url: fileData.fileUrl });
      }
    }
  }, [fileUrl]);
  return (
    <Form.Item
      label={
        type !== "checkbox" ? (
          <span style={{ color: currentThemeStyles.color }}>{label}</span>
        ) : null
      }
      required={required}
      labelCol={{ span: 24 }}
      style={{ marginBottom: "16px" }}
    >
      {type === "checkbox" ? (
        <Checkbox
          className="mt-2"
          name={name}
          checked={value}
          onChange={onChange}
          style={{
            color: currentThemeStyles.color,
          }}
        >
          {label}
        </Checkbox>
      ) : type === "file" ? (
        <>
          <Input
            type="file"
            name={name}
            onChange={handleFileChange}
            style={{
              ...currentThemeStyles,
              width: "100%",
              padding: "4px",
              border: "1px solid #d1d1d2",
              borderRadius: "4px",
            }}
          />
          {file && (
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <a
                href={file.url || URL.createObjectURL(file)}
                download={file.name}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme === "dark" ? "white" : "black" }}
              >
                Uploaded File : {file.name}
              </a>
              <TrashIcon
                onClick={() => file.url && handleFileDelete()}
                style={{
                  color: "red",
                  cursor: "pointer",
                  width: "20px",
                  marginLeft: "8px",
                }}
              />
            </div>
          )}
        </>
      ) : (
        <Input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          style={{
            ...currentThemeStyles,
            width: "100%",
            padding: "4px",
            border: "1px solid #d1d1d2",
            borderRadius: "4px",
          }}
        />
      )}
    </Form.Item>
  );
};

export default CustomInput;
