import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import PageTitle from "../../components/shared/PageTitle";
import { Button, Card, Collapse, Typography, Dropdown, Menu } from "antd";
import {
  PlusOutlined,
  FolderOutlined,
  FileOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { ChevronRight } from "@mui/icons-material";

const { Text } = Typography;
const { Panel } = Collapse;

const mockData = [
  {
    folderName: "Folder 1",
    articles: [
      { title: "Article 1.1" },
      {
        title: "Article 1.2",
        subArticles: [
          { title: "Article 1.2.1" },
          {
            title: "Article 1.2.2",
            subArticles: [{ title: "Article 1.2.2.1" }],
          },
        ],
      },
    ],
  },
  {
    folderName: "Folder 2",
    articles: [
      { title: "Article 2.1" },
      { title: "Article 2.2", subArticles: [{ title: "Article 2.2.1" }] },
    ],
  },
];

const ActionMenu = ({ onOpen, onViewDetails, theme, actionMenuStyle }) => (
  <Menu style={actionMenuStyle}>
    <Menu.Item onClick={onOpen} style={actionMenuStyle}>
      Open
    </Menu.Item>
    <Menu.Item onClick={onViewDetails} style={actionMenuStyle}>
      View Details
    </Menu.Item>
  </Menu>
);

const NestedArticles = ({ articles, theme, actionMenuStyle }) => (
  <Collapse
    bordered={false}
    expandIconPosition="start"
    expandIcon={({ isActive }) => (
      <span
        style={{
          color: theme === "dark" ? "#fff" : "#000",
          fontSize: 16,
        }}
      >
        {/* {isActive ? "▼" : "▶"} */}
        {isActive ? "▼" : <ChevronRight />}
      </span>
    )}
  >
    {articles.map((article, index) => (
      <Panel
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text style={{ color: theme === "dark" ? "#fff" : "#000" }}>
              <FileOutlined
                style={{ color: theme === "dark" ? "#bbb" : "#000" }}
              />{" "}
              {article.title}
            </Text>
            <Dropdown
              overlay={
                <ActionMenu
                  theme={theme}
                  onOpen={() => alert("Open")}
                  onViewDetails={() => alert("View Details")}
                  actionMenuStyle={actionMenuStyle}
                />
              }
              trigger={["click"]}
            >
              <EllipsisOutlined
                style={{
                  fontSize: 18,
                  cursor: "pointer",
                  color: theme === "dark" ? "#bbb" : "#000",
                }}
              />
            </Dropdown>
          </div>
        }
        key={index}
        style={{ paddingLeft: 20 }}
      >
        {article.subArticles && (
          <NestedArticles
            articles={article.subArticles}
            theme={theme}
            actionMenuStyle={actionMenuStyle}
          />
        )}
      </Panel>
    ))}
  </Collapse>
);

const Article = () => {
  const breadcrumbItems = [{ label: "Article" }];
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { cardStyle } = themeStyles(theme);
  const actionMenuStyle = {
    backgroundColor: theme === "dark" ? "#333" : "#fff",
    color: theme === "dark" ? "#fff" : "#000",
  };

  const handleAddNew = () => {
    navigate("/articles/add-article");
  };

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Article" color={cardStyle.color} />
      <Card className="mt-3" style={cardStyle}>
        <div className="row text-right">
          <div className="col-md-12">
            <Button
              type="primary"
              className="mb-4"
              style={{ float: "right" }}
              onClick={handleAddNew}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </div>
        </div>

        <Collapse
          className="mt-3"
          bordered={false}
          defaultActiveKey={[]}
          expandIconPosition="start"
          expandIcon={({ isActive }) => (
            <span
              style={{
                color: theme === "dark" ? "#fff" : "#000",
                fontSize: 16,
              }}
            >
              {/* {isActive ? "▼" : "▶"} */}
              {isActive ? "▼" : <ChevronRight />}
            </span>
          )}
        >
          {mockData.map((folder, index) => (
            <Panel
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text
                    strong
                    style={{ color: theme === "dark" ? "white" : "black" }}
                  >
                    <FolderOutlined
                      style={{ color: theme === "dark" ? "#bbb" : "#000" }}
                    />{" "}
                    {folder.folderName}
                  </Text>
                  <Dropdown
                    overlay={
                      <ActionMenu
                        theme={theme}
                        onOpen={() => alert("Open Folder")}
                        onViewDetails={() => alert("View Folder Details")}
                        actionMenuStyle={actionMenuStyle}
                      />
                    }
                    trigger={["click"]}
                  >
                    <EllipsisOutlined
                      style={{
                        fontSize: 18,
                        cursor: "pointer",
                        color: theme === "dark" ? "#bbb" : "#000",
                      }}
                    />
                  </Dropdown>
                </div>
              }
              key={index}
            >
              <NestedArticles
                articles={folder.articles}
                theme={theme}
                actionMenuStyle={actionMenuStyle}
              />
            </Panel>
          ))}
        </Collapse>
      </Card>
    </>
  );
};

export default Article;
