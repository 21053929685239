import React, { useState } from 'react';
import { Divider, Popover, Space, Typography } from 'antd';
import FilterComponent from './filterComponent';
import { filterOperatorLabels } from './filterConditionsConfig';
import TagComponent from './tagComponent';
import { format } from 'date-fns';

const FilterTags = ({ filterList, handleFilters }) => {
  const [activeField, setActiveField] = useState(null);
  const [activeFieldIndex, setActiveFieldIndex] = useState(null);

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setActiveField(null);
      setActiveFieldIndex(null);
    }
  };

  const handleTagClick = (filter, index) => {
    setActiveField(filter.field);
    setActiveFieldIndex(index);
  };

  const formatFilterValue = (value, type) => {
    if (Array.isArray(value)) {
      return value
        .map((val) =>
          type === 'date' && val ? format(new Date(val), 'dd/MM/yyyy') : val
        )
        .join(' and ');
    }
    return type === 'date' && value
      ? format(new Date(value), 'dd/MM/yyyy')
      : value || '';
  };

  return filterList?.length ? (
    <div>
      <Space>
        {filterList.map((filter, index) => (
          <Popover
            key={index}
            content={
              <div style={{ width: '300px' }}>
                <FilterComponent
                  field={filter.field}
                  initialValue={filter}
                  onApply={(newFilter) => {
                    handleFilters('update', newFilter, index);
                    setActiveField(null);
                    setActiveFieldIndex(null);
                  }}
                />
              </div>
            }
            title={
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-500 uppercase tracking-wider">
                  Edit Filter
                </div>
                <Typography.Title
                  level={5}
                  className="text-transparent bg-clip-text bg-gradient-to-r font-semibold tracking-wide mt-1"
                  style={{ marginBottom: 0 }}
                >
                  {filter.field.filterLabel}
                </Typography.Title>
                <Divider style={{ margin: '8px 0' }} />
              </div>
            }
            trigger="click"
            visible={
              activeField &&
              activeField.value === filter.field.value &&
              activeFieldIndex === index
            }
            onVisibleChange={(visible) => handleVisibleChange(visible)}
          >
            <TagComponent
              onTagClick={() => handleTagClick(filter, index)}
              onCloseClick={() => handleFilters('remove', filter, index)}
              label={`${filter.field.title} ${filterOperatorLabels[filter.condition] || ''} ${formatFilterValue(filter.value, filter?.field?.type)}`}
            />
          </Popover>
        ))}
      </Space>
    </div>
  ) : (
    <></>
  );
};

export default FilterTags;
