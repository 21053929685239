import React from "react";
import { useContext } from "react";
import ThemeContext from "./ThemeContext";

const Homepage = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      {/* <Menu
        theme={theme}
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        style={{
          backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "white",
          //   marginTop: "-32px",
        }}
      ></Menu> */}
      <div className="container text-right">
        <div className="row mt-3">
          <h1
            className={`text-4xl text-${theme === "dark" ? "white" : "dark"} font-bold`}
          >
            Global Newborn Society
          </h1>
        </div>
        <div className="row mt-3 mb-6">
          <h4
            className={`text-${theme === "dark" ? "white" : "dark"} font-bold`}
          >
            <em>Every Baby Counts!</em>
          </h4>
        </div>
      </div>
    </>
  );
};

export default Homepage;
