import React, { useState, useContext, useEffect } from "react";
import { Menu } from "antd";
import ourLogo from "./shared/Logo 013022_tif.jpg";
import logo2 from "../store/app-logo-sml.png";
import ThemeContext from "./ThemeContext";
import { useLocation } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Homepage from "./Homepage";
import Footer from "./Footer";
import JournalArchives from "../pages/Contents/JournalArchives";
import IndexPage from "../pages/Contents/IndexPage";
const items = [
  {
    label: "Us",
    key: "us",
    children: [
      {
        label: "More Information",
        key: "moreInfo",
      },
      {
        label: "Why do we need the GNS?",
        key: "why",
      },
      {
        label: "Our Organization",
        key: "ourOrg",
      },
      {
        label: "Our logo-our philosophy",
        key: "ourLogo",
      },
      {
        label: "Who we are?",
        key: "who",
      },
      {
        label: "Our founding chair and patrons",
        key: "founding",
      },
      {
        label: "Our current leadership",
        key: "leadership",
      },
      {
        label: "What can we do together?",
        key: "together",
      },
      {
        label: "When should we start?",
        key: "start",
      },
      {
        label: "Index Page",
        key: "indexPage",
      },
    ],
  },
  {
    label: "Global Presence",
    key: "globalPresence",
    children: [
      {
        label: "Collaborations",
        key: "collaborations",
      },
      {
        label: "Country Chapters",
        key: "countryChapters",
      },
      {
        label: "Missing Presence",
        key: "missingPresence",
      },
      {
        label: "Current Members",
        key: "currentMembers",
      },
      {
        label: "Fellows of the GNS 2024",
        key: "fellows",
      },
    ],
  },
  {
    label: "Programs",
    key: "programs",
    children: [
      {
        label: "GNS Statements",
        key: "gnsStatements",
      },
      {
        label: "Research from members of the GNS",
        key: "researchGns",
      },
      {
        label: "Research Needs",
        key: "researchNeeds",
      },
      {
        label: "Advocacy",
        key: "advocacy",
      },
      {
        label: "Quality Improvement",
        key: "quality",
      },
      {
        label: "Education and Training",
        key: "education",
      },
      {
        label: "Events",
        key: "events",
      },
      {
        label: "News and Blogs",
        key: "news",
      },
      {
        label: "Media",
        key: "media",
      },
      {
        label: "Correspondence",
        key: "correspondence",
      },
    ],
  },
  {
    label: "New Research",
    key: "newReseach",
  },
  {
    label: "Our Journal, The Newborn",
    key: "ourJournal",
    children: [
      {
        label: "Editor-in-Chief",
        key: "editorInChief",
      },
      {
        label: "Editorial Board",
        key: "editorialBoard",
      },
      {
        label: "How to Submit",
        key: "submit",
      },
      {
        label: "Types of Manuscripts",
        key: "manuscripts",
      },
      {
        label: "Instructions for authors",
        key: "instructions",
      },
      {
        label: "Journal Archives",
        key: "journalArchives",
      },
    ],
  },
  {
    label: "Resources",
    key: "resources",
    children: [
      {
        label: "Telegram",
        key: "telegram",
      },
      {
        label: "Conversation",
        key: "conversation",
      },
      {
        label: "Creative Corner",
        key: "creativeCorner",
      },
      {
        label: "GNS Email",
        key: "gnsEmail",
      },
      {
        label: "Facebook",
        key: "facebook",
      },
      {
        label: "RedCap",
        key: "redCap",
      },
      {
        label: "Acknowledgements",
        key: "acknowledgements",
      },
    ],
  },
  {
    label: "Please Join Us!",
    key: "joinUs",
  },
  {
    label: "Donations",
    key: "donations",
  },
  //   {
  //     key: "alipay",
  //     label: (
  //       <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
  //         Navigation Four - Link
  //       </a>
  //     ),
  //   },
];
const Header = ({ onSidebarToggle, sidebarOpen }) => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const [current, setCurrent] = useState("indexPage");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };
  const renderPage = () => {
    switch (current) {
      case "indexPage":
        return <IndexPage />;
      case "journalArchives":
        return <JournalArchives />;
      //   case "why":
      //     return <WhyPage />;
      //   case "ourOrg":
      //     return <OurOrgPage />;
      // Add other cases as needed for other menu items
      default:
        return <IndexPage />;
    }
  };
  return (
    <>
      <header
        className="header p-2 sticky top-0 z-40 w-full"
        style={{
          backgroundColor: theme === "dark" ? "#001529" : "#c8c5f7",
          color: theme === "dark" ? "#ffffff" : "#000000",
          // zIndex : 1000
        }}
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {location.pathname !== "/home" ? (
              <img
                alt="logo"
                src={logo2}
                className={`h-10 w-auto transition-width duration-300 ${"w-16"} mr-5`}
                style={{
                  filter: theme === "light" ? "invert(0)" : "invert(1)",
                }}
              />
            ) : (
              <img
                src={ourLogo}
                alt="Our Logo"
                className={`h-10 w-auto transition-width duration-300 "w-auto scale-90"`}
                style={{
                  height: "70px",
                  width: "auto",
                  // filter: theme === "light" ? "invert(0)" : "invert(1)",
                }}
              />
            )}

            {/* Search Input Field */}

            <div
              className="position-relative ms-3"
              style={{ position: "relative", width: "500px" }}
            >
              <input
                type="text"
                id="global-search"
                className={`form-control ${theme === "dark" ? "dark-theme" : "light-theme"}`}
                placeholder="Search..."
                style={{
                  width: "100%",
                  paddingRight: "30px",
                  backgroundColor: theme === "dark" ? "#333" : "#f0f0f0",
                  color: theme === "dark" ? "#fff" : "#000",
                  border: "none",
                  outline: "none",
                }}
              />
              <span
                className="searchIcon"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                  color: theme === "dark" ? "#fff" : "#000",
                }}
              >
                <MagnifyingGlassIcon className="h-5 w-5" />
              </span>
            </div>
          </div>
          {/* <div className="col-md-6">
          <p
            className={`text-${theme === "dark" ? "white" : "dark"} font-bold`}
            style={{ float: "right" }}
          >
            Hi, Welcome!
          </p>
        </div> */}

          <div className="flex items-center space-x-4">
            <div
              className={`form-check form-switch text-${
                theme === "light" ? "dark" : "light"
              } me-4`}
            >
              <img
                src={require("./shared/dark-mode.png")}
                alt="Dark Mode"
                style={{
                  cursor: "pointer",
                  width: "24px",
                  height: "24px",
                  filter: theme === "light" ? "invert(0)" : "invert(1)",
                }}
                onClick={toggleTheme}
                title="Dark Mode"
              />
            </div>
          </div>
        </div>

        {location.pathname === "/home" && (
          <Menu
            theme={theme}
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
            style={{
              backgroundColor: theme === "dark" ? "rgb(0 20 41)" : "white",
              width: "100%",
              marginTop: "5px",
            }}
          ></Menu>
        )}
      </header>
      {location.pathname === "/home" && (
        <>
          {" "}
          <Homepage />
          <div className="p-4">{renderPage()}</div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Header;
