import React, { useState } from 'react';
import { Button, Divider, Popover, TreeSelect, Typography } from 'antd';
import FilterComponent from './filterComponent';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

const AddFilterButton = ({ fields, handleFilters }) => {
  const [activeField, setActiveField] = useState(null);
  const [open, setOpen] = useState(false);

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setActiveField(null);
    }
  };

  const handleOpenChange = (value) => {
    setOpen(value);
  };

  return (
    <Popover
      open={open}
      content={
        open && activeField && activeField.title ? (
          <div style={{ width: '300px' }}>
            <FilterComponent
              field={activeField}
              onApply={(newFilter) => {
                setOpen(false);
                handleFilters('add', newFilter);
                setActiveField(null);
              }}
            />
          </div>
        ) : (
          <div style={{ width: '300px' }}>
            <TreeSelect
              treeData={fields}
              placeholder="Select Field"
              className="w-full"
              onChange={(value, label, extra) => {
                const selectedField = extra?.triggerNode?.props;
                setActiveField(selectedField);
              }}
            />
          </div>
        )
      }
      title={
        <div className="flex flex-col items-center">
          <div className="text-sm text-gray-500 uppercase tracking-wider">
            Add Filter
          </div>
          {activeField?.filterLabel && (
            <Typography.Title
              level={5}
              className="text-transparent bg-clip-text bg-gradient-to-r font-semibold tracking-wide mt-1"
              style={{ marginBottom: 0 }}
            >
              {activeField?.filterLabel}
            </Typography.Title>
          )}
          <Divider style={{ margin: '8px 0' }} />
        </div>
      }
      trigger="click"
      onOpenChange={handleOpenChange}
      visible={!!activeField && !activeField.label}
      onVisibleChange={(visible) => handleVisibleChange(visible)}
    >
      <Button type="default" onClick={() => setActiveField({})}>
        <FilterAltOutlinedIcon />
        Add Filter
      </Button>
    </Popover>
  );
};

export default AddFilterButton;
