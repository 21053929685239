import React, { useContext, useState } from "react";
import ThemeContext from "../../components/ThemeContext";
import themeStyles from "../../components/shared/ThemeStyles";
import { useFileHandlers, usePostData } from "../../hooks/useData";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/shared/CustomBreadcrumb";
import { Alert, Button, Card, Form, Tooltip } from "antd";
import CustomInput from "../../components/shared/CustomInput";
import CustomEditor from "../../components/shared/CustomEditor";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ENDPOINTS } from "../../services/endpoints";
import { showSuccessMessage } from "../../components/shared/MessageUtils";
import { useSelector } from "react-redux";
import PageTitle from "../../components/shared/PageTitle";

const AddSociety = () => {
  const breadcrumbItems = [
    { label: "Society", onClick: () => navigate("/society") },
    { label: "Add Society" },
  ];
  const { theme } = useContext(ThemeContext);
  const { cardStyle, buttonStyle } = themeStyles(theme);
  const [formValues, setFormValues] = useState({
    societyTitle: "",
    societyDescription: "",
    societyLogo: "",
  });
  const [isValidForm, setIsValidForm] = useState(true);
  const { mutate } = usePostData();
  const navigate = useNavigate();
  const files = useSelector((state) => state.file.files);
  const { handleFileChange } = useFileHandlers();
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCancel = () => {
    setFormValues({
      societyTitle: "",
      societyDescription: "",
      societyLogo: "",
    });
    navigate("/society");
  };

  const saveSociety = () => {
    if (formValues.societyTitle === "") {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }

    const body = {
      id: 0,
      society_title: formValues.societyTitle,
      society_description: formValues.societyDescription,
      society_logo_path_base64: files.societyLogo?.fileBase64 || "",
      society_logo_path_file_name: files.societyLogo?.fileName || "",
      active_status: true,
    };
    console.log(body);

    mutate(
      {
        url: ENDPOINTS.createOrUpdateSociety,
        data: body,
      },
      {
        onSuccess: (response) => {
          showSuccessMessage("Subject saved successfully!");
          setTimeout(() => {
            navigate("/society");
          }, 1000);
        },
        onError: (err) => {
          console.log("Error!", err);
        },
      }
    );
  };

  const handleTextChange = (name, updatedData) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: updatedData,
    }));
  };

  return (
    <>
      <CustomBreadcrumb items={breadcrumbItems} />
      <PageTitle title="Add Society" color={cardStyle.color} />
      {!isValidForm && (
        <Alert
          className="mt-3"
          message="Please fill mandatory(*) fields."
          type="error"
          showIcon
          closable
        />
      )}
      <Card className="mt-3 p-4" style={cardStyle}>
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          style={{ padding: "20px" }}
        >
          <div className="row">
            <div className="col-md-4">
              <CustomInput
                label="Society Title"
                type="text"
                required
                name="societyTitle"
                value={formValues.societyTitle}
                onChange={handleChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <CustomEditor
                label="Society Description"
                name="societyDescription"
                value={formValues.societyDescription}
                onChange={handleTextChange}
                theme={theme}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              {files.societyLogo?.fileError && (
                <div style={{ color: "red" }}>
                  {files.societyLogo?.fileError}
                </div>
              )}
              <CustomInput
                label={
                  <span className="flex">
                    Society Logo{" "}
                    <span className="ms-2">
                      <Tooltip title="Allowed formats: .jpg, .gif or .png; Recommend pixel size:[100px width x 100px height])">
                        <InformationCircleIcon
                          style={{
                            cursor: "pointer",
                            color: theme === "dark" ? "white" : "black",
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    </span>
                  </span>
                }
                required
                type="file"
                name="societyLogo"
                value={formValues.societyLogo}
                onChange={(e) =>
                  handleFileChange(
                    e,
                    ["image/jpeg", "image/png", "image/gif"],
                    1,
                    { width: 100, height: 100 },
                    "societyLogo"
                  )
                }
                theme={theme}
              />
            </div>
          </div>
          <div className="text-right">
            <Button
              style={buttonStyle}
              type="submit"
              onClick={saveSociety}
              className="me-2"
            >
              Save
            </Button>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: theme === "dark" ? "#444" : "#f5f5f5",
                color: theme === "dark" ? "white" : "black",
              }}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default AddSociety;
