export const filterOperatorLabels = {
  equals: 'Equals',
  'not-equals': 'Not Equals',
  greater: 'Greater',
  like: 'Like',
  'not-like': 'Not like',
  'greater-equals': 'Greater Equals',
  less: 'Less',
  'less-equals': 'Less Equals',
  between: 'Between',
  'is-null': 'Is Null',
  'is-not-null': 'Is Not Null',
  in: 'In',
  'not-in': 'Not In',
};

export const filterConditionsByType = {
  selection: [
    'equals',
    'not-equals',
    'in',
    'not-in',
    'like',
    'not-like',
    'is-null',
    'is-not-null',
  ],
  string: [
    'equals',
    'not-equals',
    'in',
    'not-in',
    'like',
    'not-like',
    'is-null',
    'is-not-null',
  ],
  number: [
    'equals',
    'not-equals',
    'in',
    'not-in',
    'greater',
    'greater-equals',
    'less',
    'less-equals',
    'between',
    'is-null',
    'is-not-null',
  ],
  date: [
    'like',
    'not-like',
    'greater',
    'greater-equals',
    'less',
    'less-equals',
    'between',
    'is-null',
    'is-not-null',
  ],
};
