const fields = [
  { title: 'Id', filterLabel: 'Id', value: 'id', type: 'number' },
  {
    title: 'Platform Name',
    filterLabel: 'Platform Name',
    value: 'platform_name',
    type: 'string',
  },
  {
    title: 'Platform Description',
    filterLabel: 'Platform Description',
    value: 'platform_description',
    type: 'string',
  },
];

export default fields;
