import React, { useState, useContext } from "react";
import { Card, Collapse, Typography } from "antd";
import ThemeContext from "../../components/ThemeContext";
import { ChevronRight } from "@mui/icons-material";
import ArticleCard from "./ArticleCard";
import ArticleContent from "./ArticleContent";

const { Text } = Typography;
const { Panel } = Collapse;

const articles = [
  {
    title: "EDITORIAL",
    author: "Akhil Maheshwari , Kei Lui , Mario Motta",
    description:
      "Healthcare Bundles are Potentially Important in Neonatal Care as Many Disorders are Temporally Clustered",
    doi: "DOI: 10.5005/newborn-3-3-iv",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_newborn-3-3-iv/graphic/JNB-3-iv.jpg",
  },
  {
    title: "ORIGINAL RESEARCH",
    author:
      "Jargalsaikhan Badarch , Gunjana Kumar , Budzaya Enkhbayar , Terkhen Turbat , Tegshjargal Sereenendorj , Batbayar Tumurkhuleg , Srijan Singh , Alvaro Dendi , Amita Singhal , Akhil Maheshwari , On behalf of the Global Newborn Society (GNS) Down Syndrome Foundation",
    description:
      "Down Syndrome is the Leading Indication for Late-stage Termination of Pregnancy in Mongolia",
    doi: "DOI: 10.5005/jp-journals-11002-0099",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_jp-journals-11002-0099/graphic/JNB-3-180.jpg",
  },
  {
    title: "REVIEW ARTICLE",
    author: "Taherah Mohammadabadi , Gunjana Kumar",
    description:
      "Camel Milk as a Source of Nutrients and Immunogens for Infants",
    doi: "DOI: 10.5005/jp-journals-11002-0106",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_jp-journals-11002-0106/graphic/JNB-3-195.jpg",
  },
  {
    title: "ORIGINAL RESEARCH",
    author: "The LAYA Group of the Global Newborn Society , Aimen E Ben Ayad",
    description:
      "A Care-bundle to Prevent Germinal Matrix–Intraventricular Hemorrhage in Neonates",
    doi: "DOI: 10.5005/jp-journals-11002-0107",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_jp-journals-11002-0107/graphic/JNB-3-157.jpg",
  },
  {
    title: "REVIEW ARTICLE",
    author:
      "Srijan Singh , Adrianna Frydrysiak-Brzozowska , Aimen E Ben Ayad , Saida S Khasanova , Jeremias Bordon , Colin Michie",
    description:
      "A Primer on Epigenetic Changes: The More We Know, the More We Find in Fetuses and Infants",
    doi: "DOI: 10.5005/jp-journals-11002-0104",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_jp-journals-11002-0104/graphic/JNB-3-219.jpg",
  },
  {
    title: "CASE REPORT",
    author: "Vikas V Jha , Girish Arora , Vinika Arora",
    description:
      "Two Novel Mutations Associated with Familial Chylomicronemia in a Neonate",
    doi: "DOI: 10.5005/jp-journals-11002-0105",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_jp-journals-11002-0105/graphic/JNB-3-238.jpg",
  },
  {
    title: "REVIEW ARTICLE",
    author:
      "Reema Garegrat , Chinmay Chetan , Chandrakala BS , Rema Nagpal , Jayanta Hazarika , Nikita Jethwa , Rajendra Puri , Ogtay Huseynov , Akhil Maheshwari , Pradeep Suryawanshi",
    description:
      "Cranial Ultrasound as an Imaging Modality in Neonatal Sepsis to Determine Involvement of the Central Nervous System",
    doi: "DOI: 10.5005/jp-journals-11002-0103",
    imageUrl:
      "https://d45jl3w9libvn.cloudfront.net/jaypee/static/journals/JNB/3/3/10.5005_jp-journals-11002-0103/graphic/JNB-3-206.jpg",
  },
];

const mockData = [
  {
    folderName: "VOL 3",
    articles: [
      { title: "VOL 3(3)", content: "Content for Vol 3(3)" },
      { title: "VOL 3(2)", content: "Content for Vol 3(2)" },
      { title: "VOL 3(1)", content: "Content for Vol 3(1)" },
    ],
  },
  {
    folderName: "VOL 2",
    articles: [
      { title: "VOL 2(4)", content: "Content for Vol 2(4)" },
      { title: "VOL 2(3)", content: "Content for Vol 2(3)" },
      { title: "VOL 2(2)", content: "Content for Vol 2(2)" },
    ],
  },
];

const NestedArticles = ({
  articles,
  theme,
  selectedArticle,
  onSelectArticle,
}) => (
  <div>
    {articles?.map((article, index) => (
      <div key={index} style={{ paddingLeft: 15 }}>
        <Text
          style={{
            color:
              article.title === selectedArticle?.title
                ? "blue"
                : theme === "dark"
                  ? "#fff"
                  : "#000",
            fontWeight:
              article.title === selectedArticle?.title ? "bold" : "normal",
            cursor: "pointer",
          }}
          onClick={() => onSelectArticle(article)}
        >
          {article.title}
        </Text>
      </div>
    ))}
  </div>
);

const JournalArchives = () => {
  const { theme } = useContext(ThemeContext);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleSelectArticle = (article) => {
    setSelectedArticle(article);
  };

  const onreset = () => {
    setSelectedArticle(null);
  };
  const pageBackgroundColor = theme === "dark" ? "#001529" : "#f5f5f5";
  const cardStyle = {
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#fff",
    color: theme === "dark" ? "white" : "#001529",
    borderRadius: "10px",
    boxShadow:
      theme === "dark"
        ? "0px 0px 10px rgba(255, 255, 255, 0.2)"
        : "0px 0px 10px rgba(0, 0, 0, 0.1)",
  };

  return (
    <div
      style={{
        backgroundColor: pageBackgroundColor,
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <div className="row p-2">
        <h3
          className={`text-xl text-${theme === "dark" ? "white" : "dark"} font-bold`}
          onClick={onreset}
        >
          Journal Archives
        </h3>
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        {/* Left Navigation Menu */}
        <Card style={{ ...cardStyle, width: "250px", padding: "10px" }}>
          <Collapse
            bordered={false}
            defaultActiveKey={[]}
            expandIconPosition="start"
            expandIcon={({ isActive }) => (
              <span
                style={{
                  color: theme === "dark" ? "#fff" : "#000",
                  fontSize: 12,
                }}
              >
                {isActive ? "▼" : <ChevronRight />}
              </span>
            )}
          >
            {mockData?.map((folder, index) => (
              <Panel
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Text
                      strong
                      style={{ color: theme === "dark" ? "white" : "black" }}
                    >
                      {folder.folderName}
                    </Text>
                  </div>
                }
                key={index}
              >
                <NestedArticles
                  articles={folder.articles}
                  theme={theme}
                  selectedArticle={selectedArticle}
                  onSelectArticle={handleSelectArticle}
                />
              </Panel>
            ))}
          </Collapse>
        </Card>

        {/* Content Section */}
        <div style={{ flex: 1 }}>
          {selectedArticle ? (
            // Display ArticleContent when an article is selected
            <ArticleContent article={selectedArticle} />
          ) : (
            // Display Article Cards when no article is selected
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "20px",
              }}
            >
              {articles?.map((article, index) => (
                <ArticleCard
                  key={index}
                  title={article.title}
                  author={article.author}
                  description={article.description}
                  doi={article.doi}
                  imageUrl={article.imageUrl}
                  theme={theme}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JournalArchives;
